import { KeyValPair } from '@models/common'
import apiGateway from '@utils/axiosInterceptor'
import { UserMetaModel } from './model/admin.model'
import { GroupType } from './model/group.model'
import { UserInfo } from './model/user.model'

const GetUserInfo = async (email: string) => {
  const encodedEmail = encodeURIComponent(email)
  return await apiGateway.get<UserInfo>(`/api/v1/users/info?email=${encodedEmail}`).then((rep) => {
    return rep.data
  })
}

const GetRoleMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/users/roles/meta').then((rep) => {
    return rep.data
  })
}

const GetRoleMetaListByContract = async ( id: number ) => {
  return await apiGateway.get<KeyValPair[]>(`/api/v1/users/roles/meta?contract=${id}`).then((rep) => {
    return rep.data
  })
}

const GetUserMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/users/meta').then((rep) => {
    return rep.data
  })
}

const GetGroupTypeList = async () => {
  return await apiGateway.get<GroupType[]>('/api/v1/groups/meta').then((rep) => {
    return rep.data
  })
}

// same as GetGroupTypeList but ignore user team, district
const GetFullGroupTypeList = async () => {
  return await apiGateway.get<GroupType[]>('/api/v1/groups/meta/full').then((rep) => {
    return rep.data
  })
}

const GetUserMetaEmailList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/users/meta/email').then((rep) => {
    return rep.data
  })
}

const RegisterFcmToken = async (deviceToken: string, platform: string) => {
  return await apiGateway
    .post(`/api/v1/users/register-device`, {
      deviceToken: deviceToken,
      platform: platform,
    })
    .then((rep) => {
      return rep.status === 204
    })
    .catch((x) => {
      return false
    })
}

const UnregisterFcmToken = async (deviceToken: string) => {
  return await apiGateway
      .put(`/api/v1/users/unregister-device`, {
        deviceToken: deviceToken,
      })
      .then((rep) => {
        return rep.status === 204
      })
      .catch((x) => {
        return false
      })
}
const GetUserMetaFullList = async () => {
  return await apiGateway.get<UserMetaModel[]>('/api/v1/users/meta/extra_info').then((rep) => {
    return rep.data
  })
}

const UserService = {
  GetUserInfo,
  GetRoleMetaList,
  GetRoleMetaListByContract,
  GetUserMetaList,
  GetGroupTypeList,
  GetFullGroupTypeList,
  GetUserMetaEmailList,
  RegisterFcmToken,
  UnregisterFcmToken,
  GetUserMetaFullList
}

export default UserService
