import FormContainer      from '@components/form/container'
import FormField          from '@components/form/field'
import FormTable          from '@components/form/table'
import useGeneralOptions  from '@hooks/useGeneralOptions_to_be_used'
import { Typography }     from '@mui/material'
import { FormStatusEnum } from '@services/model/form/form.model'
import { FormScrModel }   from '@services/model/form/form.SCR.model'
import { Control }        from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default({
  control,
  useWatch,
  setValue,
  getValues,
  formStatus
}: {
  control: Control<FormScrModel>
  useWatch: any
  setValue: any
  getValues: any
  formStatus: string
})=> {
  const { getOptionsByKey } = useGeneralOptions()
  const { t } = useTranslation()
  const editable =
    formStatus === FormStatusEnum.FORM_SCR_DRAFT || formStatus === FormStatusEnum.FORM_SCR_REJECTED

  return (
      <FormContainer fkey={`scr-d`}>
        <FormField fieldName="Type" index={0}>
          <Typography>{t('Constructional Plant')}</Typography>
        </FormField>

          <FormTable columns={
              [{
                  field      : 'description',
                  displayName: 'Description',
                  type       : 'string'
              },
               {
                   field      : 'number',
                   displayName: 'Number',
                   type       : 'number'
               },
               {
                   field      : 'resourceTypeId',
                   displayName: 'Resource Type',
                   type       : 'select',
                   options    : getOptionsByKey('Form SD/SCR - Construction Plant')
               },
               {
                   field               : 'resourceTypeOthers',
                   displayName         : 'Other',
                   type                : 'string',
                   disableFieldCallback: (name: string, datumIndex: number) => {
                       return getOptionsByKey('Form SD/SCR - Construction Plant')
                                      ?.find((x) => x.value === 'Others')?.key !== getValues(`${name}.${datumIndex}.resourceTypeId`)
                   }
               }]
          }
                     useWatch={ useWatch }
                     fieldArrayProps={ {
                         name: 'contractorResources',
                         control
                     } }
                     uneditable={ !editable } />
      </FormContainer>
    )
}

