import { KeyValPair } from '@models/common'
import { GlobalContext } from '@providers/globalStore'
import { useContext } from 'react'

function useGeneralOptions(): {
  getOptionsByKey: (key: string) => KeyValPair[]
} {
  const { state } = useContext(GlobalContext)
  const getOptionsByKey = (key: string) => {
    if (state.generalOptionGroupList) {
      return (
        state.generalOptionGroupList
          .find((x) => x.name.toLowerCase() === key.toLowerCase())
          ?.generalOptions
          ?.filter((x) => x.status)
          ?.map((x) => {
            return { key: x.id, value: x.name, status: x.status, parentId: x.parentId  }
          }) || []
      )
    }
    return []
  }

  return { getOptionsByKey }
}

export default useGeneralOptions
