import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormTypography from '@components/form/typography'
import { TextField } from '@mui/material'
import { FormStatusEnum } from '@services/model/form/form.model'
import { FormDNModel } from '@services/model/form/form.DN.model'
import { t } from 'i18next'
import { Control } from 'react-hook-form'
import FormImageUpload from '@components/form/imageUpload'
import { ImageModel } from '@services/model/image.model'
import FormFreeText from '@components/form/freeText'
import uuid from 'react-uuid'

const UUID = uuid()

export default ({
  control,
  formStatus,
  setValue
}: {
  control: Control<FormDNModel>
  formStatus: string,
  setValue: any
}) => {
    const readonly = !(
        formStatus === FormStatusEnum.FORM_DN_ISSUED ||
        formStatus === FormStatusEnum.FORM_DN_ENDORSEMENT_REJECTED
      )

  return  (
    <>
    <FormContainer fkey={`${UUID}-dn-b`}>
          <FormField fieldName="Images">
            <FormController
              controllerProps={{
                name: 'appealImages',
                control,
              }}>
              <FormImageUpload
                uneditable={readonly}
                additionalChangeAction={(value: ImageModel[]) => {
                  if (value.length > 0 && value[value.length-1].location) {
                    setValue('location', value[value.length-1].location)
                    setValue('roadName', value[value.length-1].location?.locationAddress)
                  }
                }}
                multiple
              />
            </FormController>
          </FormField>
          <FormField fieldName="Reason for Appeal">
            <FormController
              controllerProps={{
                name: 'reasonForAppeal',
                control,
              }}>
              <FormFreeText
                textfieldProps={{
                  multiline: true,
                  rows: 7,
                }}
                uneditable={readonly}
              />
            </FormController>
          </FormField>
        </FormContainer>
    </>
  )
}
