import SearchPanel from '@components/searchPanel'
import { Button, Grid, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import * as common from '@common/common'
import { GlobalContext } from '@providers/globalStore'
import { InitialSearchPanelState, SearchPanelContext } from '@providers/SearchPanelProvider'
import SearchPanelReducer, { SearchPanelState } from '@reducers/searchPanelReducer'
import moment from 'moment'
import { Fragment, useContext, useReducer, useRef, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import useTableView from '@hooks/useTableView'
import { useTranslation } from 'react-i18next'
import FormStatusLabel from '@components/FormStatusLabel'
import GetValueWithKey from '@utils/getValueWithKey'

import { FormScrListModel, ScrFilterOptions } from '@services/model/form/form.SCR.model'
import { NavigateTo } from '@utils/navigate'
import FormScrService from '@services/formService/form.SCR.service'
import { KeyValPair } from '@models/common'
import { FormStatusEnum, RightsCategory } from '@services/model/form/form.model'
import { Box } from '@mui/material'
import svgUrl from '@images/file-copy-line.svg'
import { DialogContextProps } from '@components/form/switcher'
import { useForm } from 'react-hook-form'
import { cloneDeep } from 'lodash'
import FormController from '@components/form/controller'
import FormSelect from '@components/form/select'
import FormDateTimePicker from '@components/form/dateTimePicker'
import { Stack } from '@mui/system'
import FormDialog from '@components/form/dialog_v2'

const DialogFormField = (props: { children: any; fieldName: string }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      sx={{
        display: 'flex',
        padding: 1,
        alignItems: 'center',
        gap: 1,
        ...(matchDownMd && {
          flexDirection: 'column',
          alignItems: 'flex-start',
        }),
      }}>
      <Box
        {...(!matchDownMd && {
          sx: {
            width: 200,
          },
        })}>
        <Typography>{t(props.fieldName)}:</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          width: '100%',
        }}>
        {props.children}
      </Box>
    </Box>
  )
}

const AllRecord = (props: { showMyRecord?: boolean }) => {
  const refMounted = useRef(false)
  const theme = useTheme()
  const { state: globalState, userInfo } = useContext(GlobalContext)
  const [state, dispatch] = useReducer<React.Reducer<SearchPanelState, any>>(
    SearchPanelReducer,
    InitialSearchPanelState,
  )

  const { t } = useTranslation()
  const [extraListInfo, setExtraListInfo] = useState<ScrFilterOptions>({
    submittedByList: [],
    approvedByList: [],
    formIdList: [],
    workOrderIdList: [],
  })

  const getExtraListInfo = async () => {
    let extraInfo = await FormScrService.GetScrFilterOption(props.showMyRecord)
    setExtraListInfo(extraInfo)
    refMounted.current = true
  }

  const [TableView, reload] = useTableView<FormScrListModel>({
    headers: [
      {
        key: 'formStatusShortName',
        desc: t('General Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormScrListModel) => (
          <Fragment>
            <FormStatusLabel
              label={record.formStatusShortName}
              color={record.formStatusColor || ''}
            />
          </Fragment>
        ),
      },
      {
        key: 'formStatus',
        desc: t('Submission Status', { ns: common.i18nFormNS }),
        renderCell: (record: FormScrListModel) => <Fragment>{record.formStatus}</Fragment>,
      },
      {
        key: 'formId',
        desc: t('Form ID', { ns: common.i18nFormNS }),
        renderCell: (record: FormScrListModel) => <Fragment>{record.reportNo}</Fragment>,
      },
      {
        key: 'inspectionDate',
        desc: t('Inspection Date', { ns: common.i18nFormNS }),
        renderCell: (record: FormScrListModel) => (
          <Fragment>
            {record.inspectionDate
              ? moment(record.inspectionDate).format(moment.defaultFormat)
              : ''}
          </Fragment>
        ),
      },
      {
        key: 'contractNo',
        desc: t('Contract No.', { ns: common.i18nFormNS }),
        renderCell: (record: FormScrListModel) => <Fragment>{record.contractNo}</Fragment>,
      },
      {
        key: 'workOrderId',
        desc: t('Work Order No', { ns: common.i18nFormNS }),
        renderCell: (record: FormScrListModel) => <Fragment>{record.workOrderNo}</Fragment>,
      },
      {
        key: 'location',
        desc: t('Location', { ns: common.i18nFormNS }),
        renderCell: (record: FormScrListModel) => (
          <Fragment>{record.location ? record.location : ''}</Fragment>
        ),
      },
      {
        key: 'submittedBy',
        desc: t('Submitted By', { ns: common.i18nFormNS }),
        renderCell: (record: FormScrListModel) => (
          <Fragment>{GetValueWithKey(record.submittedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'approvedBy',
        desc: t('Approved By', { ns: common.i18nFormNS }),
        renderCell: (record: FormScrListModel) => (
          <Fragment>{GetValueWithKey(record.approvedBy, globalState.userMetaList ?? [])}</Fragment>
        ),
        mobileHidden: true,
      },
      {
        key: 'updatedAt',
        desc: t('Last Update', { ns: common.i18nFormNS }),
        renderCell: (record: FormScrListModel) => (
          <Fragment>
            {record.updatedAt ? moment(record.updatedAt).format(moment.defaultFormat) : ''}
          </Fragment>
        ),
        mobileHidden: true,
      },
    ],

    onRowClick: function (navigate: NavigateFunction, record: FormScrListModel) {
      NavigateTo(navigate, '/site-check-record/:id', { id: record.formId })
    },
    mountedRef: refMounted,
    reloadCallback: async (pagination: object, sorting: object | undefined, cancelToken: any) => {
      if (refMounted.current) {
        sessionStorage.setItem('SCR', JSON.stringify(state.filterResult))
        let resp = await FormScrService.GetScrList(
          {
            ...state.filterResult,
            sorting: sorting,
            pagination: pagination,
          },
          cancelToken,
          props.showMyRecord || false,
        )
        return resp
      }
    },
  })

  const [dialogContext, setDialogContext] = useState<DialogContextProps>({
    title: <></>,
  })

  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const handleDialogOpen = () => {
    setDialogOpen(true)
  }
  const handleDialogClose = () => {
    setDialogOpen(false)
  }

  const defaultValues: {
		formId: string | null,
    reportDate: Date
	} = { formId: null, reportDate: new Date() }

  const methods = useForm({ defaultValues })

  const { control, getValues } = methods

  const navigate = useNavigate()

  const onSubmit = async() => {
    const form = getValues()
    const { formId, reportDate } = form

    const reportDateFormat = reportDate?.toISOString()

    if(formId){
      const data = await FormScrService.GetExistingScr(formId, reportDateFormat)
      if(data){
        handleDialogClose()
        navigate('/site-check-record', {
          state: { cloneData: cloneDeep(data) }, 
         })
        methods.reset()
      }
    }
  }
  const onAddExisting = async () => {
      let resp = await FormScrService.GetScrList(
        {
          pagination: {
            skipPagination: true
          },
          formStatus: {
            operator: '!=',
            value: [globalState.formStatusList?.filter((status) => { 
              return status.actionCode === FormStatusEnum.FORM_SCR_DRAFT
            })[0].id]
          }
        },
        null,
        false
      )
      const options = (resp?.list || []).map(({ formId, reportNo }) => ({ label: reportNo, value: formId }))

    setDialogContext({
      title: (
        <Typography variant="body1">
          {t('Copy Data to new Site Check Record')}
        </Typography>
      ),
      children: (
        <Box>
          <DialogFormField fieldName='Site Check Record'>
            <FormController
              controllerProps={{
                name: 'formId',
                control: control,
              }}
            >
              <FormSelect
                fullWidth
                options={options?.map((x) => {
                  return {
                    key: x.value,
                    value: x.label
                  }
                })}
              />
            </FormController>
          </DialogFormField>
          <DialogFormField fieldName='Target Date'>
            <FormController
              controllerProps={{
                name: 'reportDate',
                control: control,
              }}
            >
              <FormDateTimePicker uneditable={false}/>
            </FormController>
          </DialogFormField>
        </Box>
        ),
      buttons: (
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={onSubmit}>
            {t('Confirm')}
          </Button>
          <Button variant="outlined" onClick={handleDialogClose}>
            {t('Cancel')}
          </Button>
        </Stack>
      ),
    })
    handleDialogOpen()
	}

  return (
    <Grid component="main" container padding={1}>
      <SearchPanelContext.Provider value={{ state, dispatch }}>
        <SearchPanel
          dispatch={dispatch}
          addUrl="/booking/new"
          onSearch={reload}
          onInitReload={reload}
          onToggleFilterDrawer={getExtraListInfo}
          mountedRef={refMounted}
          excludeStatus={true}
          criteria={[
            {
              key: 'formStatus',
              desc: 'Submission Status',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList:
                globalState.formStatusList
                  ?.filter((x) => x.actionCode.includes(RightsCategory.FORM_SCR))
                  ?.map((x) => {
                    return {
                      key: x.id,
                      value: x.actionName,
                    }
                  }) || [],
              defaultValue:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.formStatus?.value
                  : [],
            },
            {
              key: 'formId',
              desc: 'Form ID',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: extraListInfo.formIdList,
              defaultValue:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.formId?.value
                  : [],
            },
            {
              key: 'inspectionDate',
              desc: 'Inspection Date',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.inspectionDate?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.inspectionDate?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.inspectionDate?.value?.max
                  : '',
            },
            {
              key: 'contractNo',
              desc: 'Contract No.',
              type: 'NumberSelectionFilter',
              multiple: true,
              valueList: globalState.contractList?.map((x) => {
                return {
                  key: x.id,
                  value: x.contractNo,
                }
              }),
              defaultValue:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.contractNo?.value
                  : [],
            },
            {
              key: 'workOrderId',
              desc: 'Works Order No',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.worksOrderList?.filter((x) =>
                extraListInfo.workOrderIdList?.includes(x.id),
              )?.map(({ id, workOrderNo}) => ({ key: id, value: workOrderNo})),
              defaultValue:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.workOrderId?.value
                  : [],
            },
            {
              key: 'location',
              desc: 'Location',
              type: 'StringFilter',
              defaultValue:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.location?.value
                  : '',
            },
            {
              key: 'submittedBy',
              desc: 'Submitted By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.submittedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.submittedBy?.value
                  : [],
            },
            {
              key: 'approvedBy',
              desc: 'Approved By',
              type: 'StringSelectionFilter',
              multiple: true,
              valueList: globalState.userMetaList?.filter((x) =>
                extraListInfo.approvedByList?.includes(x.key),
              ),
              defaultValue:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.approvedBy?.value
                  : [],
            },
            {
              key: 'updatedAt',
              desc: 'Last Update',
              type: 'DateFilter',
              defaultOperator:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.updatedAt?.operator
                  : 'in',
              defaultFrom:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.updatedAt?.value?.min
                  : '',
              defaultTo:
                sessionStorage?.SCR !== 'undefined' && sessionStorage?.SCR !== undefined
                  ? JSON.parse(sessionStorage?.SCR)?.updatedAt?.value?.max
                  : '',
            },
          ]}
          extraButtons={
            <IconButton
              sx={{
                backgroundColor: globalState.headerColor,
                borderRadius: '10%',
                width: '40px',
                height: '40px',
                '&:hover': { background: globalState.headerColor },
              }}
              onClick={onAddExisting}
              >
              <Button variant="text" >
                <Box style={{ height: 20, width: 20 }}>
                    <img src={svgUrl} alt="road-icon" style={{ height: 'inherit', width: 'inherit' }} />
                </Box>
              </Button>
            </IconButton>
          }
        />
        <TableView />
      </SearchPanelContext.Provider>
      <FormDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        toolbarStyle={dialogContext.toolbarStyle}
        title={dialogContext.title}
        buttons={dialogContext.buttons}
        children={dialogContext.children}
      />
    </Grid>
  )
}

export default AllRecord
