import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import FormSelect from '@components/form/select'
import { Control, UseFormSetValue } from 'react-hook-form'
import { Typography } from '@mui/material'
import { FormHWRModel, HWRGeneralOptions } from '@services/model/form/form.HWR.model'
import FormFileUpload from '@components/form/fileUpload'
import { GlobalStateProps } from '@providers/globalStore'
import { BaseFormPermission, FormStatusEnum } from '@services/model/form/form.model'
import FormHWRService from '@services/formService/form.HWR.service'
import { useEffect } from 'react'
import { KeyValPair } from '@models/common'
import FormMultipleSelect from '@components/form/multipleSelect'
import { GetTeamOptions } from '@utils/teamOptions'
import { GetDistrictOptions } from '@utils/districtOptions'
import FormTypography from '@components/form/typography'
import { GetWorksOrderOptions } from '@utils/worksOrderOptions'

export default ({
  globalState,
  control,
  userInfo,
  watch,
  setValue,
  getOptionsByKey,
  getValues,
  formStatus,
  formPermission,
  readOnly,
  options,
  useWatch,
}: {
  globalState: GlobalStateProps
  control: Control<FormHWRModel>
  userInfo: any
  watch: any
  setValue: UseFormSetValue<FormHWRModel>
  getOptionsByKey: any
  getValues: any
  formStatus: string
  formPermission: BaseFormPermission
  readOnly: boolean
  options?: HWRGeneralOptions
  useWatch: any
}) => {
  const uneditable = !(
    formStatus === FormStatusEnum.FORM_HWR_DRAFT ||
    formStatus === FormStatusEnum.FORM_HWR_REVIEW ||
    formStatus === FormStatusEnum.FORM_HWR_REJECTED ||
    formStatus === FormStatusEnum.FORM_HWR_ENDORSEMENT ||
    formStatus === FormStatusEnum.FORM_HWR_NOT_ENDORSED ||
    formStatus === FormStatusEnum.FORM_HWR_APPROVED ||
    formStatus === FormStatusEnum.FORM_HWR_COUNTERSIGNED
  )
  const [contractNoId] = watch(['baseForm.contractNoId'])
  const matchedContract = globalState.contractList?.find(
    (contractNo) => contractNo.id === contractNoId,
  )

  let index = 0
  const WorkOrderField = () => {
    const [contractNoId, teamId, districtId] = useWatch({
      name: ['baseForm.contractNoId', 'baseForm.teamId', 'baseForm.districtId'],
      control: control,
    })
  

    return (
      <>
        <FormField fieldName="Works Order No">
          {readOnly ? (
            <FormTypography value={getValues('baseForm.workOrderNo')} />
          ) : (
            <FormController
              controllerProps={{
                name: 'baseForm.workOrderId',
                control: control,
              }}>
              <FormSelect
                fullWidth
                options={GetWorksOrderOptions(
                  globalState.worksOrderList || [],
                  teamId, 
                  districtId)}
                uneditable={readOnly}
                additionalChangeAction={(event: any, newValue: KeyValPair | null) => {
                  let workOrderId = newValue?.key
                  if (
                    workOrderId &&
                    (formStatus === FormStatusEnum.FORM_HWR_DRAFT ||
                      formStatus === FormStatusEnum.FORM_HWR_REJECTED)
                  ) {
                    FormHWRService.GetHwRefList(workOrderId).then((resp) => {
                      setValue('referenceOfHwr', resp?.referenceOfHwr || [])
                    })
                  }
                }}
              />
            </FormController>
          )}
        </FormField>
      </>
    )
  }

  const [referenceOfHwr] = useWatch({
    name: ['referenceOfHwr'],
    control: control,
  })

  const HiddenWorksRef = () => {
    return (
      <FormField fieldName="Temporary Hidden Works Record">
        <FormController
          controllerProps={{
            name: 'referenceOfHwr',
            control: control,
          }}>
          <FormFileUpload disabled multipleUpload hideUpload hideRemove />
        </FormController>
      </FormField>
    )
  }

  return (
    <FormContainer>
      <FormField fieldName="Contract No." index={index++}>
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control: control,
          }}>
          <FormSelect
            fullWidth
            options={
              globalState.contractList?.map((x) => {
                return {
                  key: x.id,
                  value: x.contractNo,
                }
              }) || []
            }
            uneditable={true}
          />
        </FormController>
      </FormField>
      <FormField fieldName="Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control,
          }}>
          <FormMultipleSelect
            options={GetTeamOptions(userInfo.teams)}
            uneditable={readOnly}
            additionalChangeAction={(event) => {
              setValue('baseForm.workOrderId', undefined)
            }}
          />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control,
          }}>
          <FormMultipleSelect
            options={GetDistrictOptions(userInfo.districts)}
            uneditable={readOnly}
          />
        </FormController>
      </FormField>

      <WorkOrderField />

      <FormField fieldName="HWR No." index={index++}>
        <Typography>{getValues('hwrNo')}</Typography>
      </FormField>

      <HiddenWorksRef />
    </FormContainer>
  )
}
