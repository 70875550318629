import { KeyValPair } from '@models/common'
import { InitUserInfo, UserInfo } from '@services/model/user.model'
import React, { createContext, useCallback, useMemo, useReducer } from 'react'
import useLocalStorage from '@hooks/useLocalStorage'
import GlobalReducer, { ActionType } from '../reducers/globalReducer'
import {
  GeneralConfig,
  GeneralOptionDNGroup,
  GeneralOptionGroup,
} from '@services/model/general.model'
import { ContractNo, WorkOrder } from '@services/model/contract.model'
import { FormCountByUser, FormStatus } from '@services/model/form/form.model'
import { useKeycloak } from '@react-keycloak/web'
import { UserMetaModel } from '@services/model/admin.model'

export interface GlobalStateProps {
  userMetaList?: KeyValPair[]
  organizationMetaList?: KeyValPair[]
  roleMetaList?: KeyValPair[]
  userMetaEmailList?: KeyValPair[]
  userMetaFullList?: UserMetaModel[]

  contractList?: ContractNo[]
  contractAndWorksOrderList?: ContractNo[] //deprecatd - not using
  generalConfigList?: GeneralConfig[]
  generalOptionGroupList?: GeneralOptionGroup[]
  generalOptionDNGroupList?: GeneralOptionDNGroup[]

  teamList?: KeyValPair[]
  districtList?: KeyValPair[]

  proposedActivityList?: KeyValPair[]
  weatherList?: KeyValPair[]
  essentialWorkList?: KeyValPair[]
  worksOrderList?: WorkOrder[]

  formStatusList?: FormStatus[]
  formOptionsList?: KeyValPair[]
  totalFormCountList?: FormCountByUser[]

  leftDrawerOpened: boolean
  headerColor: string
  openedMenuId: string | undefined // passing a menu id
  title: string
  subtitle?: string
  formStatus?: string
  workOrder?: string
  contractNoId: number
  workGroupId: number
  isInitiated: boolean
}

const initialGlobalState: GlobalStateProps = {
  leftDrawerOpened: true,
  openedMenuId: undefined,
  title: 'HyD RMMS',
  headerColor: 'rgb(255, 202, 97)',
  contractNoId: 0,
  workGroupId: 0,
  isInitiated: false,
}

export const GlobalContext = createContext<{
  userInfo: UserInfo
  state: GlobalStateProps
  dispatch: React.Dispatch<ActionType>
  isAuthenticated: () => boolean
  hasRightByCatAndCode: (
    category: string,
    rightsCode: string[],
    matchWholeWord?: boolean,
  ) => boolean
}>({
  userInfo: InitUserInfo,
  state: initialGlobalState,
  dispatch: () => undefined,
  isAuthenticated: () => false,
  hasRightByCatAndCode: (category: string, rightsCode: string[], matchWholeWord?: boolean) => false,
})

export const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalReducer, initialGlobalState)
  const [loginUser, setLoginUser] = useLocalStorage<UserInfo>('usr', InitUserInfo)
  const { keycloak, initialized } = useKeycloak()

  const isAuthenticated = useCallback(() => {
    return keycloak.authenticated || false
  }, [keycloak])

  const hasRightByCatAndCode = useCallback(
    (category: string, rightsCode: string[], matchWholeWord?: boolean) => {
      if (loginUser.allRights) {
        return true
      }

      let found: string | undefined

      if (matchWholeWord) {
        found = loginUser.categoryRights
          ?.find((x) => x.category.toLowerCase() === category.toLowerCase())
          ?.rights?.find((x) => rightsCode.includes(x))
      } else {
        found = loginUser.categoryRights
          ?.find((x) => x.category.toLowerCase().includes(category.toLowerCase()))
          ?.rights?.find((x) => rightsCode.includes(x))
      }

      return found !== undefined
    },
    [loginUser],
  )

  const contextValue = useMemo(
    () => ({
      userInfo: loginUser,
      state,
      dispatch,
      isAuthenticated,
      hasRightByCatAndCode,
    }),
    [loginUser, state, dispatch, isAuthenticated, hasRightByCatAndCode],
  )

  return (
    <GlobalContext.Provider
      value={{ userInfo: loginUser, state, dispatch, isAuthenticated, hasRightByCatAndCode }}>
      {children}
    </GlobalContext.Provider>
  )
}
