import apiGateway from '@utils/axiosInterceptor'
import BaseSaveResponse from '../model/base.model'
import { FormCrListModel, FormCRModel, CRGeneralOptions, CrListResponse, CrFilterOptions } from '../model/form/form.CR.model'
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'

const GetGeneralOptions = async () => {
  return await apiGateway.get<CRGeneralOptions>(`/api/v1/forms/CR/options`).then((rep) => {
    return rep.data
  })
}

const GetCRForm = async (formId: string) => {
  return await apiGateway
    .get<FormCRModel>(`api/v1/forms/CR/${formId}`)
    .then((rep) => {
      return rep.data
    })
}

const GetCrList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<CrListResponse>(
      `api/v1/forms/CR/${myRecordOnly ? 'my-action' : 'list'}`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      return rep.data
    })
}

const GetCrFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/CR/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<CrFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const SaveCRForm = async (req: FormCRModel) => {
  if (req.baseForm.formId === '') {
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/CR', req).then((rep) => {
      return rep.data
    })
  } else {
    return await apiGateway
      .put<BaseFormResponse>(
        `api/v1/forms/CR/${req.baseForm.formId}`,
        req,
      )
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveCRForm = async (req: FormCRModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/CR/${req.baseForm.formId}/approve`, req)
    .then((rep) => {
      return rep.data
    })
}

const RejectCRForm = async (req: FormCRModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/CR/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const DeleteCRForm = async (req: FormCRModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/CR/${req.baseForm.formId}`,
  )
}

export default {
  GetCRForm,
  GetCrList,
  GetCrFilterOption,
  SaveCRForm,
  ApproveCRForm,
  RejectCRForm,
  DeleteCRForm,
  GetGeneralOptions,
}
