import apiGateway from '@utils/axiosInterceptor'
import { NeaGeneralOptions, FormNeaListModel, FormNeaModel, NeaListResponse, NeaFilterOptions } from '../model/form/form.NEA.model'
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'

const GetNEAOptions = async (team?: number, district?: number) => {
  if(team === undefined && district === undefined){
    return await apiGateway
      .get<NeaGeneralOptions>(`api/v1/forms/NEA/options`)
      .then((rep) => {
        return rep.data
      })
  }else{
    return await apiGateway
      .get<NeaGeneralOptions>(`api/v1/forms/NEA/options?district=${district}&team=${team}`)
      .then((rep) => {
        return rep.data
      })
  }
}

const GetNeaForm = async (formId: string) => {
  return await apiGateway
    .get<FormNeaModel>(`api/v1/forms/NEA/${formId}`)
    .then((rep) => {
      return rep.data
    })
}

const GetNeaList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<NeaListResponse>(
      `api/v1/forms/NEA/${myRecordOnly ? 'my-action' : 'list'}`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      return rep.data
    })
}

const GetNeaFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/NEA/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<NeaFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const SaveNeaForm = async (req: FormNeaModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/NEA', req).then((rep) => {
      return rep.data
    })
  else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/NEA/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveNeaForm = async (req: FormNeaModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/NEA/${req.baseForm.formId ? req.baseForm.formId : '_'}/approve`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const RejectNeaForm = async (req: FormNeaModel) => {
  return await apiGateway
    .put<BaseFormResponse>(`api/v1/forms/NEA/${req.baseForm.formId}/reject`, req)
    .then((rep) => {
      return rep.data
    })
}

const ChangeOfficer = async (req: FormNeaModel) => {
  return await apiGateway
          .put<string>(`api/v1/forms/NEA/${req.baseForm.formId}/change-officer`, {
            auditingOfficer: req.auditingOfficer,
            checkingOfficer: req.checkingOfficer
          })
          .then((rep) => rep.data)
}

const DeleteNeaForm = async (req: FormNeaModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/NEA/${req.baseForm.formId}`,
  )
}

const FormNeaService = {
  GetNeaForm,
  GetNeaList,
  GetNeaFilterOption,
  SaveNeaForm,
  ApproveNeaForm,
  RejectNeaForm,
  ChangeOfficer,
  DeleteNeaForm,
  GetNEAOptions,
}

export default FormNeaService
