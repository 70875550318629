import { ForwardedRef, forwardRef, Fragment, useContext, useEffect, useState } from 'react'
import FormContainer                                                           from '@components/form/container'
import FormController                                                          from '@components/form/controller'
import FormDateTimePicker                                                      from '@components/form/dateTimePicker'
import FormField                                                               from '@components/form/field'
import FormSelect                                                              from '@components/form/select'
import { Control, UseFormSetValue }                                            from 'react-hook-form'
import uuid                                                                    from 'react-uuid'
import { Box }                                                                 from '@mui/material'
import {
  BaseFormPermission,
  FormStatusEnum,
  InitFormModel,
  InitFormStatusHistory,
  RightsCategory
}                                                                              from '@services/model/form/form.model'
import FormTypography                                                          from '@components/form/typography'
import FormMediumMap                                                           from '@components/form/mediumMap'
import FormFreeText                                                            from '@components/form/freeText'
import Radio                                                                   from '@components/form/radio'
import FormBinaryRadio                                                         from '@components/form/binaryRadio'
import FormLinkTable                                                           from '@components/form/linkTable'
import { KeyValPair }                                                          from '@models/common'
import { GlobalContext }                                                       from '@providers/globalStore'
import { WorkOrder }                                                           from '@services/model/contract.model'
import { DNGeneralOptions }                                                    from '@services/model/form/form.DN.model'
import moment                                                                  from 'moment'
import { useTranslation }                                                      from 'react-i18next'
import BatchCreateDNDialog                                                     from './batch-create'
import useGeneralOptions                                                       from '@hooks/useGeneralOptions_to_be_used'
import FormImageUpload                                                         from '@components/form/imageUpload'
import { ImageModel }                                                          from '@services/model/image.model'
import { NavigateTo }                                                          from '@utils/navigate'
import { useNavigate }                                                         from 'react-router-dom'
import { FormNFModel, NFGeneralOptions }                                       from '@services/model/form/form.NF.model'
import FormMultipleSelect                                                      from '@components/form/multipleSelect'
import { GetTeamOptions }                                                      from '@utils/teamOptions'
import { GetDistrictOptions }                                                  from '@utils/districtOptions'
import { GetWorksOrderOptions }                                                from '@utils/worksOrderOptions'

declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

function PartAInner ({
  control,
  formStatus,
  userInfo,
  useWatch,
  nfGeneralOptions,
  setValue,
  isMounted,
  watch,
  hasParentForm,
  getValues,
  dnOptions,
  formPermission,
  formStateAction,
  formId,
  handleOnApproval
}: {
  control: Control<FormNFModel>
  formStatus: string
  userInfo: any
  useWatch: any
  nfGeneralOptions: NFGeneralOptions
  setValue: UseFormSetValue<FormNFModel>
  isMounted: any
  watch: any
  hasParentForm: boolean
  getValues: any
  dnOptions: DNGeneralOptions | undefined
  formPermission: BaseFormPermission
  formStateAction: any
  formId?: string
  handleOnApproval?:any
}, ref: ForwardedRef<HTMLUListElement>){
  const UUID = uuid()
  const navigate = useNavigate()

  const PartADefectCategory = ({
    control,
    scopeOptions,
    options,
    readonly,
  }: {
    control: Control<FormNFModel>
    scopeOptions: KeyValPair[]
    options: KeyValPair[]
    readonly: boolean
  }) => {
    const scopeOfWorksId = useWatch({
      name: 'scopeOfWorksId',
      control,
    })

    const founded = scopeOptions?.find((o) => o.key === scopeOfWorksId)
    const matched = founded ? founded.value === 'M&M Works' : false

    if (matched) {
      return (
        <FormField fieldName="Defect Category">
          <FormController
            controllerProps={{
              name: 'defectCategoryId',
              rules: { required: true },
              control,
            }}>
            <FormSelect fullWidth options={options} uneditable={readonly} />
          </FormController>
        </FormField>
      )
    }
    return <></>
  }

  const handleLinkClick = (index: number) => {
    formStateAction({ type: 'push', form: getValues(), formType: 'NF' })
    NavigateTo(navigate, '/default-notice-for-non-compliance/:formId', { formId }, { num: index })
  }

  const PartADefectIdentified = ({
    control,
    scopeOptions,
    setValue,
    nfGeneralOptions,
    readonly,
  }: {
    control: Control<FormNFModel>
    scopeOptions: KeyValPair[]
    setValue: UseFormSetValue<FormNFModel>
    nfGeneralOptions?: NFGeneralOptions
    readonly: boolean
  }) => {
    const [scopeOfWorksId, defectCategoryId] = useWatch({
      name: ['scopeOfWorksId', 'defectCategoryId'],
      control,
    })

    const founded = scopeOptions.find((o) => o.key === scopeOfWorksId)
    const matched = founded ? founded.value === 'M&M Works' : false

    const matchedDefectOptions =
      nfGeneralOptions?.defectCategory
        ?.find((category) => {
          return defectCategoryId === category.id
        })
        ?.defectOptions?.map((x) => {
          return {
            key: x.id,
            value: x.defectCode + '. ' + x.description,
          }
        }) || []

    if (matched) {
      return (
        <FormField fieldName="Defect Identified">
          <FormController
            controllerProps={{
              name: 'defectOptionId',
              control: control,
              rules: { required: true },
            }}>
            <FormSelect fullWidth options={matchedDefectOptions} uneditable={readonly} />
          </FormController>
        </FormField>
      )
    }
    return <></>
  }

  const PartARectificationAt = ({
                                  control,
                                  nfGeneralOptions,
                                  scopeOptions,
                                  formStatus
                                }: {
    control: Control<FormNFModel>
    nfGeneralOptions?: NFGeneralOptions
    scopeOptions: KeyValPair[]
    formStatus: string
  }) => {
    const [scopeOfWorksId, defectCategoryId, defectOptionId, timeLimitId] = useWatch({
                                                                                       control,
                                                                                       name: ['scopeOfWorksId', 'defectCategoryId', 'defectOptionId', 'timeLimitId']
                                                                                     })

    const matchedDefectOptions = nfGeneralOptions?.defectCategory?.find(category => defectCategoryId === category.id)?.defectOptions

    const founded              = scopeOptions?.find(o => o.key === scopeOfWorksId)
    const selectedDefectOption = matchedDefectOptions?.find(option => defectOptionId === option.id)

    const timeLimits = matchedDefectOptions?.find(option => defectOptionId === option.id)?.timeLimits || []

    const defectCode = selectedDefectOption?.description
    const matched    = founded
                       ? founded.value === 'M&M Works' && defectCode && !defectCode.includes('Non-compliance item')
                       : false

    const editable = formStatus === FormStatusEnum.FORM_NF_DRAFT || formStatus === FormStatusEnum.FORM_NF_ISSUE_REJECTED

    const _clearDueDate = () => setValue('dueDate', undefined)

    return (
        <>
          <FormField fieldName='Approved Date'
                     hidden={ formStatus !== FormStatusEnum.FORM_NF_RECTIFICATION || !matched }>
            <FormController controllerProps={ { name: 'rectificationAt', control: control } }>
              <FormTypography type='time' />
            </FormController>
          </FormField>

          <FormField fieldName='Time Limit' hidden={ !matched }>
            <FormController controllerProps={ { name: 'timeLimitId', control: control } }>
              {
                timeLimits?.length > 0 ?
                <FormSelect fullWidth
                            options={
                                timeLimits?.map(x => ({
                                  key  : x.id,
                                  value: x.timeLimit
                                })).concat([{ key: 0, value: 'Others' }]) || []
                            }
                            uneditable={ !editable }
                            additionalChangeAction={ _clearDueDate } /> :
                <FormTypography type='string' value='N/A' />
              }
            </FormController>
          </FormField>

          <FormField fieldName='Due Date' hidden={ !matched }>
            <FormController controllerProps={ { name: 'dueDate', control: control } }>
              {
                timeLimitId == 0 ?
                <FormDateTimePicker type='date'
                                    minDate={ new Date(+new Date() + 86400000) }
                                    uneditable={ !editable } /> :
                <FormTypography type='time' />
              }
            </FormController>
          </FormField>
        </>
    )
  }

  const PartAFollowUpAction = ({
    control,
    scopeOptions,
    formStatus,
  }: {
    control: Control<FormNFModel>
    scopeOptions: KeyValPair[]
    formStatus: string
  }) => {
    const [scopeOfWorksId] = useWatch({
      name: ['scopeOfWorksId'],
      control,
    })

    const founded = scopeOptions?.find((o) => o.key === scopeOfWorksId)
    const hidden = founded
      ? founded.value === 'Not Under HyD Purview' &&
        (formStatus === FormStatusEnum.FORM_NF_NOT_UNDER_HYD_PURVIEW ||
          formStatus === FormStatusEnum.FORM_NF_NOT_UNDER_HYD_PURVIEW_FOLLOWED_UP)
      : false

    const editable = formStatus === FormStatusEnum.FORM_NF_NOT_UNDER_HYD_PURVIEW

    return (
      <Fragment>
        <FormField fieldName="Follow-up Action" hidden={!hidden}>
          <FormController
            controllerProps={{
              name: 'followUpAction',
              control: control,
            }}>
            <FormBinaryRadio
              binaryOptionNames={{ yes: 'Reported Not Under HyD to', no: 'Referred to' }}
              uneditable={!editable}
            />
          </FormController>
        </FormField>

        <FormField fieldName="Follow-up By" hidden={!hidden}>
          <FormController
            controllerProps={{
              name: 'followUpActionTo',
              control: control,
            }}>
            <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={!editable} />
          </FormController>
        </FormField>
      </Fragment>
    )
  }

  const PartACoveringWorkOrder = ({
    control,
    scopeOptions,
    formStatus,
    workOrderList,
  }: {
    control: Control<FormNFModel>
    scopeOptions: KeyValPair[]
    formStatus: string
    workOrderList: WorkOrder[] | undefined
  }) => {
    const [scopeOfWorksId] = useWatch({
      name: ['scopeOfWorksId'],
      control,
    })

    const founded = scopeOptions?.find((o) => o.key === scopeOfWorksId)
    const hidden = founded
      ? founded.value === 'Outside M&M Scope' &&
        (formStatus === FormStatusEnum.FORM_NF_OUTSIDE_M_M ||
          formStatus === FormStatusEnum.FORM_NF_OUTSIDE_M_M_FOLLOWED_UP)
      : false

    const editable = formStatus === FormStatusEnum.FORM_NF_OUTSIDE_M_M

    return (
      <FormField fieldName="Issued Works Order" hidden={!hidden}>
        <FormController
          controllerProps={{
            name: 'coveringWorkOrder',
            control: control,
          }}>
          <FormSelect
            options={
              workOrderList?.map((x) => {
                return {
                  key: x.id,
                  value: x.workOrderNo,
                }
              }) || []
            }
            uneditable={!editable}
          />
        </FormController>
      </FormField>
    )
  }

  const PartA_DnTable = ({
    control,
    scopeOptions,
    dnOptions,
    handleLinkClick,
    formPermission,
    watch
  }: {
    control: Control<FormNFModel>
    scopeOptions: KeyValPair[]
    dnOptions: DNGeneralOptions | undefined
    handleLinkClick: (index: number) => void
    formPermission: BaseFormPermission
    watch: any
  }) => {
    const [selectedStatus, setSelectedStatus] = useState<string>("")

    useEffect(() => {
      if (getValues('formDn') !== undefined && getValues('formDn').filter(x => x.approveState === true).length > 0) {
        setSelectedStatus(getValues('formDn')?.find(x => x.approveState === true)?.baseForm.formStatusName)
      } else if (getValues('formDn') !== undefined && getValues('formDn').filter(x => x.approveState === true).length === 0){
        setSelectedStatus('')
      }
    }, [])

    useEffect(() => {
      const subscription = watch((data) => {
        if (data.formDn !== undefined && data.formDn.filter(x => x.approveState === true).length === 1) {
          setSelectedStatus(data.formDn?.find(x => x.approveState === true)?.baseForm.formStatusName)
        } else if (data.formDn !== undefined &&data.formDn.filter(x => x.approveState === true).length === 0){
          setSelectedStatus('')
        }
      })
  
      return () => subscription.unsubscribe()
    }, [watch])

    const [scopeOfWorksId] = useWatch({
      name: ['scopeOfWorksId'],
      control,
    })

    const { t } = useTranslation()
    const { hasRightByCatAndCode } = useContext(GlobalContext)
    const founded = scopeOptions?.find((o) => o.key === scopeOfWorksId)
    const hidden = founded
      ? founded.value !== 'M&M Works' 
      : false

    const [showSelectionBox, setShowSelectionBox] = useState<boolean>(true)
    const enableSelectionButton = formStatus !== FormStatusEnum.FORM_NF_DRAFT && 
     formStatus !== FormStatusEnum.FORM_NF_REVIEW &&  
     formStatus !== FormStatusEnum.FORM_NF_ISSUE_REJECTED


    if (hidden) {
      return <></>
    }

    return (
      <FormLinkTable
        columns={[
          {
            field: 'approveState',
            name: '',
            type: 'checkbox',
            hidden: showSelectionBox,
            disabled:  (_row) => {
              const row = _row as any
              if(row.baseForm.formPermission.canBatchApproval && selectedStatus === ""){
                return false  
              }else if(row.baseForm.formPermission.canBatchApproval && selectedStatus !== "" && row.baseForm.formStatusName === selectedStatus){
                return false  
              }else{
                return true
              }
            },
          },
          {
            field: 'nonComplianceId',
            name: 'DN Ref No.',
            type: 'selected',
            options: dnOptions
              ? dnOptions.typeOfNonComplianceList?.map((t) => ({
                  key: t.id,
                  value: t.dnRefNo,
                }))
              : [],
          },
          {
            field: 'nonComplianceId',
            name: 'Type of Non-Compliance',
            type: 'selected',
            options: dnOptions
              ? dnOptions.typeOfNonComplianceList?.map((t) => ({
                  key: t.id,
                  value: t.typeOfNonCompliance,
                }))
              : [],
          },
          {
            field: 'nonComplianceId',
            name: 'Amount of Deduction',
            type: 'selected',
            options: dnOptions
              ? dnOptions.typeOfNonComplianceList?.map((t) => ({
                  key: t.id,
                  value: t.amountOfDeduction,
                }))
              : [],
            mobileHidden: true,
          },
          {
            field: '',
            name: 'NC Period',
            type: 'custom',
            render: (_row) => {
              const row = _row as any
              return (
                <Box>
                  <Box>{moment(row.startAt).format()}</Box>-<Box>{moment(row.endAt).format()}</Box>
                </Box>
              )
            },
          },
          {
            field: 'baseForm.formStatusName',
            name: 'Status',
            type: 'string',
            mobileHidden: true,
          },
        ]}
        fieldArrayProps={{
          control: control,
          name: 'formDn',
        }}
        title={t('Non-Compliance')}
        onLinkClick={handleLinkClick}
        disabled={formPermission?.endOfFlow}
        addButtonText={'Create Default Notice'}
        addWithPopupConponment={(isOpen: boolean, handleClose: () => void, append: any) => {
          return (
            <BatchCreateDNDialog
              isOpen={isOpen}
              onClickOpen={handleClose}
              onGenerate={(state) => {
                if (state.frequency !== undefined) {
                  // appending dn with state values
                  for (let i = 0; i < state.noOfDnGenerated!; i++) {
                    append({
                      baseForm: {
                        ...InitFormModel,
                        formStatus: FormStatusEnum.FORM_DN_DRAFT,
                        formStatusName: 'Draft',
                        formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
                      },
                      appealImages: [],
                      startAt: new Date(state.startOfNcPeroid!.getTime() + i * state.frequency),
                      endAt: new Date(state.startOfNcPeroid!.getTime() + (i + 1) * state.frequency),
                      nonComplianceId: state.nonComplianceId!,
                    })
                  }
                } else {
                  append({
                    baseForm: {
                      ...InitFormModel,
                      formStatus: FormStatusEnum.FORM_DN_DRAFT,
                      formStatusName: 'Draft',
                      formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
                    },
                    appealImages: [],
                    startAt: state.startOfNcPeroid!,
                    endAt: state.endOfNcPeroid!,
                    nonComplianceId: state.nonComplianceId!,
                  })
                }

                handleClose()
              }}
              dnOptions={dnOptions}
            />
          )
        }}
        uneditable={
          formPermission?.endOfFlow || !hasRightByCatAndCode(RightsCategory.FORM_DN, ['C'], true)
        }
        enableSelectionButton={enableSelectionButton}
        showSelectionBox={showSelectionBox}
        setShowSelectionBox={(showSelectionBox) => setShowSelectionBox(!showSelectionBox)}
        handleOnApproval={handleOnApproval}
      />
    )
  }

  const { state: globalState } = useContext(GlobalContext)
  const { t } = useTranslation()

  console.log('globalState', globalState)

  const contractNoList =
    globalState.contractList?.map((x) => {
      return {
        key: x.id,
        value: x.contractNo,
      }
    }) || []

  const readonly =
    formStatus !== FormStatusEnum.FORM_NF_DRAFT &&
    formStatus !== FormStatusEnum.FORM_NF_ISSUE_REJECTED
  const [contractNoId, teamId, districtId, scopeOfWorksId] = useWatch({
    name: ['baseForm.contractNoId', 'baseForm.teamId', 'baseForm.districtId', 'scopeOfWorksId'],
    control,
  })

  const { getOptionsByKey } = useGeneralOptions()
  const scopeOfWorkList = getOptionsByKey('Form NF - Scope of Works')
  const converingWorkOrderList = globalState.worksOrderList?.filter((x) => x.coveringWorkOrder === true)

  const PartAWorksOrderNo = () => {

    let mmWorks = scopeOfWorkList?.find((x) => x.key === scopeOfWorksId)?.value === 'M&M Works'

    if (mmWorks) {
      return (
        <>
          <FormField fieldName="Work Order No.">
            {readonly ? <FormTypography value={getValues('baseForm.workOrderNo')}/> : 
            <FormController
              controllerProps={{
                name: 'baseForm.workOrderId',
                control: control,
                rules: { required: true },
              }}>
              <FormSelect fullWidth options={GetWorksOrderOptions(
                    globalState.worksOrderList || [],
                    teamId, 
                    districtId)} uneditable={readonly} />
            </FormController>}
          </FormField>
        </>
      )
    } else {
      return <></>
    }
  }

  return (
    <FormContainer fkey={`${UUID}-nf-a`}>
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control: control,
            rules: { required: true },
          }}>
          <FormSelect fullWidth options={contractNoList} uneditable={true} />
        </FormController>
      </FormField>

      <FormField fieldName="Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control,
          }}>
          <FormMultipleSelect
            options={hasParentForm || readonly ? globalState.teamList || [] : GetTeamOptions(userInfo.teams)}
            uneditable={hasParentForm || readonly}
            additionalChangeAction={(event) => {
              setValue("baseForm.workOrderId", undefined)
            }}
          />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control,
          }}>
          <FormMultipleSelect
            options={hasParentForm || readonly ? globalState.districtList || [] : GetDistrictOptions(userInfo.districts)}
            uneditable={hasParentForm || readonly}
            additionalChangeAction={(event) => {
              setValue("baseForm.workOrderId", undefined)
            }}
          />
        </FormController>
      </FormField>

      <FormField fieldName="Scope of Works">
        <FormController
          controllerProps={{
            name: 'scopeOfWorksId',
            control: control,
          }}>
          <Radio
            labels={scopeOfWorkList}
            uneditable={readonly}
            additionalChangeAction={(scopeOfWorksId: number) => {
              let selected = scopeOfWorkList?.find((x) => x.key === scopeOfWorksId)
              if (selected) {
                if (selected.value !== 'M&M Works') {
                  if (getValues('baseForm.formId') === '') {
                    setValue('formDn', [])
                  } else {
                    getValues('formDn')?.map((x, idx) => {
                      setValue(`formDn.${idx}.baseForm.status`, false)
                    })
                  }
                  setValue('defectCategoryId', undefined)
                  setValue('defectOptionId', undefined)
                  setValue('baseForm.workOrderId', undefined)
                }
              }
            }}
          />
        </FormController>
      </FormField>

      <PartAWorksOrderNo />

      <Fragment>
        <PartADefectCategory
          control={control}
          scopeOptions={scopeOfWorkList}
          options={
            nfGeneralOptions?.defectCategory?.map((x) => {
              return {
                key: x.id,
                value: x.name,
              }
            }) || []
          }
          readonly={readonly}
        />

        <PartADefectIdentified
          control={control}
          setValue={setValue}
          scopeOptions={scopeOfWorkList}
          nfGeneralOptions={nfGeneralOptions}
          readonly={readonly}
        />

        <FormField fieldName="EI No">
          <FormController
            controllerProps={{
              name: 'eiNo',
              control: control,
            }}>
            <FormTypography />
          </FormController>
        </FormField>

        <FormField fieldName="NF No">
          <FormController
            controllerProps={{
              name: 'nfNo',
              control: control,
            }}>
            <FormTypography />
          </FormController>
        </FormField>
        <FormField fieldName="Image of Defect">
          <FormController
            controllerProps={{
              name: 'images',
              control: control,
            }}>
            <FormImageUpload
              uneditable={readonly}
              additionalChangeAction={(value: ImageModel[]) => {
                if (value.length > 0 && value[value.length-1].location) {
                  setValue('location', value[value.length-1].location)
                  setValue('roadName', value[value.length-1].location?.locationAddress)
                }
              }}
              multiple
            />
          </FormController>
        </FormField>

        <FormField fieldName="Location">
          <FormController
            controllerProps={{
              name: 'location',
              control,
            }}>
            <FormMediumMap
              additionalChangeAction={async (address) => {
                setValue('roadName', address)
              }}
              uneditable={readonly}
              isMounted={isMounted.current}
              preventBrowserLocation={formStatus === FormStatusEnum.FORM_NF_ISSUE_REJECTED}
              ref={ref}
            />
          </FormController>
        </FormField>
        <FormField fieldName="Road">
          <FormController
            controllerProps={{
              name: 'roadName',
              control: control,
            }}>
            <FormTypography />
          </FormController>
        </FormField>

        <FormField fieldName="Location Remarks">
          <FormController
            controllerProps={{
              name: 'locationRemark',
              control: control,
            }}>
            <FormFreeText textfieldProps={{ fullWidth: true }} uneditable={readonly} />
          </FormController>
        </FormField>

        <FormField fieldName="Date of Checking">
          <FormController
            controllerProps={{
              name: 'dateOfCheck',
              control: control,
              rules: { required: true },
            }}>
            <FormDateTimePicker type="datetime" uneditable={readonly} currentTimeBtn={true}/>
          </FormController>
        </FormField>

        <PartAFollowUpAction
          control={control}
          scopeOptions={scopeOfWorkList}
          formStatus={formStatus}
        />

        <PartACoveringWorkOrder
          control={control}
          scopeOptions={scopeOfWorkList}
          formStatus={formStatus}
          workOrderList={converingWorkOrderList}
        />

        <PartARectificationAt control={ control }
                              scopeOptions={ scopeOfWorkList }
                              nfGeneralOptions={ nfGeneralOptions }
                              formStatus={ formStatus } />

        <FormField fieldName="Remarks">
          <FormController
            controllerProps={{
              name: 'remarks',
              control: control,
            }}>
            <FormFreeText
              textfieldProps={{
                fullWidth: true,
                multiline: true,
                rows: 10,
              }}
              sx={{
                width: '90%',
              }}
              uneditable={readonly}
            />
          </FormController>
        </FormField>

        <PartA_DnTable
          control={control}
          scopeOptions={scopeOfWorkList}
          dnOptions={dnOptions}
          handleLinkClick={handleLinkClick}
          formPermission={formPermission}
          watch={watch}
        />
      </Fragment>
    </FormContainer>
  )
}

export const PartA = forwardRef( PartAInner )