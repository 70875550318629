import { FileModel } from '../file.model'
import {
  BaseFormStatusDtoForList,
  FormModel,
  FormStatusEnum,
  InitFormModel,
  InitFormStatusHistory,
} from './form.model'
import { BaseFilterOptions, BaseListResponse } from '../base.model'
import { KeyValPair } from '@models/common'
import { ContractNo } from '../contract.model'

export interface FormHWRModel {
  baseForm: FormModel

  date?: Date
  typeOfHwrId?: number
  // hwrDetails?: HWRDetail[]
  //dailyRecordAttachments?: FileModel[]

  // a
  hwrNo?: string
  referenceOfHwr?: FileModel[]

  // b
  lastSubmission?: boolean
  hwrAttachments?: FileModel[]
  remarks?: string

  //Section B Hidden Work Review
  wsCheckedOnSite?: boolean
  checkedSupportingEvidenceId?: number[]
  evidenceOthers?: string
  reviewRemarks?: string
  //Section B Hidden Work Review By IOW
  iowChecked?: boolean
  iowCheckedOnSite?: boolean
  iowRemarks?: string

  // e - signature
  signatureRequired: boolean
  signatureBase64?: string
  notifyUserList?: string[]
  submissionComment?: string
}

export interface FormHwRefModel {
  referenceOfHwr?: FileModel[]
}

export interface HwrListResponse extends BaseListResponse {
  list: FormHwrListModel[]
}

export interface HwrFilterOptions extends BaseFilterOptions {
  workOrderIdList: number[]
}

// export interface HWRDetail {
//     id: number
//     status: boolean
//     description?: string
//     quantity?: string
// }

export interface FormHwrListModel extends BaseFormStatusDtoForList {
  formId: number
  color: string
  formStatus: string
  formStatusShortName: string
  formStatusColor: string

  reportNo: string
  contractNo: string
  workOrderNo: string

  submittedBy: string
  approvedBy: string
  updatedAt: Date
}

export const InitHWRForm: FormHWRModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.FORM_HWR_DRAFT,
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },
  signatureRequired: false,
}

export interface HWRGeneralOptions {
  checkedSupportingEvidenceList: {
    id: number
    name: string
  }[]
}
