import { Fragment, useContext, useEffect, useRef } from 'react'
import { useNavigate, useParams }                  from 'react-router-dom'
import { useForm }                                 from 'react-hook-form'
import { GlobalContext }                           from '@providers/globalStore'
import { FormPartComponents }                      from '@models/common'
import { useTranslation }                          from 'react-i18next'
import useQuery                                    from '@hooks/useQuery'
import { QueryStepParser }                         from '@utils/queryStepParser'
import { FormContext }                             from '@providers/formStateProvider'
import uuid                                        from 'react-uuid'
import useFormSwitcher                             from '@hooks/useFormSwitcher'
import { FormCRModel, InitCRForm }                 from '@services/model/form/form.CR.model'
import useAPIFetch                                 from '@hooks/useAPIFetch'
import { useSnackbar }                             from 'notistack'
import FormService                                 from '@services/form.service'
import FormCRService                               from '@services/formService/form.CR.service'
import { NavigateTo }                              from '@utils/navigate'
import { FormStatusEnum }                          from '@services/model/form/form.model'
// parts
import CRPartA                                     from './part-a'
import CRPartB                                     from './part-b'
import CRPartC                                     from './part-c'
import CRPartD                                     from './part-d'
import React                                       from 'react'
import { yupResolver }                             from '@hookform/resolvers/yup'
import * as yup                                    from 'yup'
import ValidationToast                             from '@components/form/ValidationToast'
import getFormValidationSchema                     from '@utils/formValidationSchema'
import { find, get }                               from 'lodash'
import useLocalStorage                             from '@hooks/useLocalStorage'

const UUID = uuid()

export default () => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))

  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()
  const { enqueueSnackbar } = useSnackbar()

  const isMounted = useRef<boolean>(false)

  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_CR_DRAFT, FormStatusEnum.FORM_CR_WORKS_INCOMPLETE],
      validationSchema: yup.object().shape({
        baseForm: yup.object().shape({
          contractNoId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0, t('Contract No. is required'))
            .required(t('Contract No. is required')),
          teamId: yup.array().nullable().min(1).required(t('Team is required')),
          districtId: yup.array().nullable().min(1).required(t('District is required')),
          workOrderId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0)
            .required(t('Works Order No. is required')),
        }),
        dateOfCompleteion: yup.date().required(t('Date of Completion of Works is required')),
        applySbv: yup.boolean().required(t('Apply SBV is required')),
      }),
    },
    {
      formStatusList: [
        FormStatusEnum.FORM_CR_WORKS_COMPLETION_REVIEW,
        FormStatusEnum.FORM_CR_WORKS_COMPLETION_ENDORSEMENT_REJECTED,
      ],
      validationSchema: yup.object().shape({
        iowAgreementOnCompletion: yup.boolean().required(t('Apply SBV is required')),
        reasonOfIncompletion: yup.string().when('iowAgreementOnCompletion', {
          is: false,
          then: yup.string().required(t('Reason of Incompletion is required')),
          otherwise: yup.string().nullable(),
        }),
        outstandingWorks: yup.boolean().when('iowAgreementOnCompletion', {
          is: true,
          then: yup.boolean().required(t('Outstanding Works is required')),
          otherwise: yup.boolean().nullable(),
        }),
        outstandingDetails: yup.string().when('outstandingWorks', {
          is: true,
          then: yup.string().required(t('Outstanding Works Details is required')),
          otherwise: yup.string().nullable(),
        }),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_CR_WORKS_COMPLETION_ENDORSEMENT],
      validationSchema: yup.object().shape({
        ectoAgreementOnCompletion: yup
          .boolean()
          .required(t('E/CTO Agreement on Completion is required')),
      }),
    },
    {
      formStatusList: [
        FormStatusEnum.FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS,
        FormStatusEnum.FORM_CR_OUTSTANDING_WORKS_INCOMPLETE,
      ],
      validationSchema: yup.object().shape({
        crOutstandingWorks: yup
          .array()
          .of(
            yup.object().shape({
              dateOfOutstandingWorksCompleteion: yup
                .date()
                .nullable()
                .required(t('Date of Outstanding Works Completion is required')),
            }),
          )
          .nullable(),
      }),
    },
    {
      formStatusList: [
        FormStatusEnum.FORM_CR_OUTSTANDING_WORKS_REVIEW,
        FormStatusEnum.FORM_CR_WORKS_COMPLETION_CERTIFIED,
        FormStatusEnum.FORM_CR_OUTSTANDING_WORKS_COMPLETED,
      ],
      validationSchema: yup.object(),
    },
  ]

  const { control, watch, setValue, getValues, reset, trigger } = useForm<FormCRModel>({
    defaultValues: { ...InitCRForm },
    resolver: (data, context, options) => {
      const validatorSchema = getFormValidationSchema(
        data.baseForm.formStatus,
        validationSchemaConditions,
      )
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  const [formStatus, formPermission] = getValues(['baseForm.formStatus', 'baseForm.formPermission'])
  const [editable]                   = getValues(['baseForm.editable'])

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormCRService.GetCRForm(formId)
            .then(async (f) => {
              if (f) {
                reset(f)
                if (
                  f.baseForm.contractNoId &&
                  f.baseForm.contractNoId !== globalState.contractNoId
                ) {
                  globalAction({
                    type: 'selectContract',
                    contractNoId: f.baseForm.contractNoId,
                  })

                  globalAction({
                    type: 'selectWorkGroup',
                    workGroupId: get(
                      find(globalState.contractList, { id: f.baseForm.contractNoId }),
                      'workGroups.0.id',
                      0,
                    ),
                  })
                }
                globalAction({
                  type: 'changeFormStatus',
                  formStatus: f.baseForm.formStatusName,
                })
              }
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, { variant: 'error' })
              NavigateTo(navigate, '/completion-report/all-record')
            })
        }
      },
    })
  }

  useEffect(() => {
    if (!isMounted.current) {
      if (formState.form && formState.formType === 'CR') {
        formStateAction({ type: 'pop', targetFormType: 'CR' })

        // previous editing form (new / existed form)
        reset({ ...formState.form })
      } else if (formId) {
        reload()
      } else {
        setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
        setValue('baseForm.teamId', userInfo.teams)
        setValue('baseForm.districtId', userInfo.districts)
      }

      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })
    }

    return () => {
      // formStateAction({ type: 'clear' })
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formId, formStateAction, globalAction])

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) return
    setValue('signatureBase64', signatureBase64)
    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment
    if (isReject) {
      setRequest({
        callback: async () => {
          await FormCRService.RejectCRForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/completion-report/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          console.log('form', ff)
          await FormCRService.ApproveCRForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/completion-report/all-record')
          })
        },
      })
    }
  }

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await FormCRService.SaveCRForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/completion-report/:id', {
            id: resp.formId,
          })
          formId = resp.formId
          reload()
          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormCRService.DeleteCRForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/completion-report/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
          })
      },
    })
  }

  const partA: FormPartComponents = {
    partTitle: t('Report on Completion of Works'),
    component: (
        <CRPartA globalState={ globalState }
                 control={ control }
                 userInfo={ userInfo }
                 getValues={ getValues }
                 formStatus={ formStatus } />
    )
  }

  const partB: FormPartComponents = {
    partTitle: t('Completion of Works Certification'),
    component: (
      <CRPartB
        globalState={globalState}
        control={control}
        watch={watch}
        setValue={setValue}
        formStatus={formStatus}
      />
    ),
    disabled: formStatus === FormStatusEnum.FORM_CR_DRAFT,
  }

  const partC: FormPartComponents = {
    partTitle: t('Outstanding Works'),
    component: <CRPartC globalState={globalState} control={control} formStatus={formStatus} />,
    disabled:
      !editable ||
      (formStatus !== FormStatusEnum.FORM_CR_WORKS_COMPLETED_WITH_OUTSTANDING_WORKS &&
        formStatus !== FormStatusEnum.FORM_CR_OUTSTANDING_WORKS_REVIEW &&
        formStatus !== FormStatusEnum.FORM_CR_OUTSTANDING_WORKS_COMPLETED &&
        formStatus !== FormStatusEnum.FORM_CR_OUTSTANDING_WORKS_INCOMPLETE),
  }

  const partD: FormPartComponents = {
    partTitle: t('Submission Confirmation'),
    component: (
      <CRPartD
        control={control}
        globalState={globalState}
        handleOnComplete={() => handleOnComplete()}
      />
    ),
  }

  const onLoadNotifyList = async (isReject: boolean) => {
    if (!(await trigger())) return undefined
    var defaultNotifyList = await FormService.GetNotifyList(
      'CR',
      getValues('baseForm.formId'),
      isReject,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
      [
        {
          key: 'iaoc',
          value: getValues('iowAgreementOnCompletion') ?? '',
        },
        {
          key: 'eaoc',
          value: getValues('ectoAgreementOnCompletion') ?? '',
        },
        {
          key: 'ow',
          value: getValues('outstandingWorks') ?? '',
        },
      ],
    )
    return defaultNotifyList
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('COMPLETION REPORT'),
    components: [partA, partB, partC, partD],
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate,
    disableDelete: !formPermission?.canDelete,
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired,
    endOfFlow: formPermission?.endOfFlow,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <ValidationToast control={control} />
    </Fragment>
  )
}
