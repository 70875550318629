import AdminService from '@services/admin.service'
import ContractService from '@services/contract.service'
import FormEIAService from '@services/formService/form.EIA.service'
import FormEIService from '@services/formService/form.EI.service'
import FormNFService from '@services/formService/form.NF.service'
import FormDNService from '@services/formService/form.DN.service'
import FormNeaService from '@services/formService/form.NEA.service'
import FormQSService from '@services/formService/form.QS.service'
import FormCDRService from '@services/formService/form.CDR.service'
import FormSdService from '@services/formService/form.SD.service'
import FormScrService from '@services/formService/form.SCR.service'
import FormHwrService from '@services/formService/form.HWR.service'
import FormCRService from '@services/formService/form.CR.service'
import FormTWOService from '@services/formService/form.TWO.service'
import FormWOIService from '@services/formService/form.WOI.service'
import FormWOUService from '@services/formService/form.WOU.service'
import FormDNAService from '@services/formService/form.DNA.service'
import GeneralOptionsService from '@services/general.service'
import UserService from '@services/user.service'

const apiFallback =
  (apiCall, fallback) =>
  async (...args: any[]) => {
    try {
      return await apiCall(...args)
    } catch (e) {
      return fallback
    }
  }

const getFormOptionsService = async () => {
  console.info("[Init Form Options]")
  const res = await Promise.all(
    [
      { target: 'EIA', api: FormEIAService.GetEIAOptions },
      { target: 'EI', api: FormEIService.GetGeneralOptions },
      { target: 'NF', api: FormNFService.GetGeneralOptions },
      { target: 'DN', api: FormDNService.GetGeneralOptions },
      { target: 'NEA', api: FormNeaService.GetNEAOptions },
      { target: 'QS', api: FormQSService.GetGeneralOptions },
      { target: 'CDR', api: FormCDRService.GetGeneralOptions },
      { target: 'SD', api: FormSdService.GetGeneralOptions },
      { target: 'SCR', api: FormScrService.GetGeneralOptions },
      { target: 'HWR', api: FormHwrService.GetGeneralOptions },
      // { target: 'CR', api: FormCRService.GetGeneralOptions },
      { target: 'TWO', api: FormTWOService.GetGeneralOptions },
      { target: 'WOI', api: FormWOIService.GetGeneralOptions },
      { target: 'WOU', api: FormWOUService.GetGeneralOptions },
      { target: 'DNA', api: FormDNAService.GetGeneralOptions },
    ].map(async ({ target: key, api }: { target: string; api: () => Promise<any> }) => {
      try {
        const value = await api()
        return { key, value }
      } catch (err) {
        console.error(`[GetFormOptionsService Error] - target ${key}`)
        return { key, value: {} }
      }
    }),
  )

  return res
}

export const initOptions = async () => {
  console.info("[Init Options]")
  const res = await Promise.all([
    await apiFallback(UserService.GetUserMetaList, [])({}),
    await apiFallback(AdminService.GetOrganisationMeta, [])({}),
    await apiFallback(UserService.GetUserMetaFullList, [])({}),
    await apiFallback(UserService.GetUserMetaEmailList, [])({}),
    await apiFallback(UserService.GetRoleMetaList, [])({}),
    await apiFallback(GeneralOptionsService.GetGeneralConfigList, [])({}),
  ]).then(
    ([
      reloadUserMetaList,
      reloadOrganizationMetaList,
      reloadUserMetaFullList,
      reloadUserMetaEmailList,
      reloadRoleMetaList,
      reloadGeneralConfigList,
    ]) => ({
      reloadUserMetaList,
      reloadOrganizationMetaList,
      reloadUserMetaFullList,
      reloadUserMetaEmailList,
      reloadRoleMetaList,
      reloadGeneralConfigList,
    }),
  )

  return res
}

export const initContractRelatedOptions = async () => {
  console.info("[Init Contract Related Options]")
  const res = await Promise.all([
    await apiFallback(GeneralOptionsService.GetGeneralOptionGroupList, [])({}),
    await apiFallback(GeneralOptionsService.GetGeneralOptionDNGroupList, [])({}),
    await apiFallback(UserService.GetGroupTypeList, [])({}),
    await apiFallback(GeneralOptionsService.GetFormActionList, [])({}),
    await apiFallback(ContractService.GetWorkOrderMetaList, [])(0),
    await apiFallback(getFormOptionsService, [])({}),
    await apiFallback(GeneralOptionsService.GetUserFormCount, [])({}),
  ]).then(
    ([
      reloadOptionGroupList,
      reloadDNOptionGroupList,
      reloadGroupTypeList,
      reloadFormActionList,
      reloadWorksOrderList,
      reloadFormOptionsList,
      reloadTotalFromCount
    ]) => ({
      reloadOptionGroupList,
      reloadDNOptionGroupList,
      reloadGroupTypeList,
      reloadFormActionList,
      reloadWorksOrderList,
      reloadFormOptionsList,
      reloadTotalFromCount
    }),
  )

  return res
}