import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm, useWatch } from 'react-hook-form'
import { GlobalContext } from '@providers/globalStore'
import { FormPartComponents } from '@models/common'
import { useTranslation } from 'react-i18next'
import useQuery from '@hooks/useQuery'
import { QueryStepParser } from '@utils/queryStepParser'
import uuid from 'react-uuid'
import { FormContext } from '@providers/formStateProvider'
import useGeneralOptions from '@hooks/useGeneralOptions'
import { FormHWRModel, HWRGeneralOptions, InitHWRForm } from '@services/model/form/form.HWR.model'
import HWRPartA from './part-a'
import HWRPartB from './part-b'
import HWRPartC from './part-c'
import HWRPartD from './part-d'
import FormService from '@services/form.service'
import useFormSwitcher from '@hooks/useFormSwitcher'
import useAPIFetch from '@hooks/useAPIFetch'
import FormHWRService from '@services/formService/form.HWR.service'
import { useSnackbar } from 'notistack'
import { NavigateTo } from '@utils/navigate'
import { FormStatusEnum } from '@services/model/form/form.model'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ValidationToast from '@components/form/ValidationToast'
import getFormValidationSchema from '@utils/formValidationSchema'
import { find, get } from 'lodash'
import useLocalStorage from '@hooks/useLocalStorage'

const UUID = uuid()
const HWRForm = () => {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction } = useContext(FormContext)
  const { getOptionsByKey } = useGeneralOptions()

  const navigate = useNavigate()
  const { t } = useTranslation()
  const [storageContractId, setStorageContractId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step = QueryStepParser(query.get('step'))
  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()

  const { enqueueSnackbar } = useSnackbar()
  const isMounted = useRef(false)

  const validationSchemaConditions = [
    {
      formStatusList: [FormStatusEnum.FORM_HWR_DRAFT, FormStatusEnum.FORM_HWR_REJECTED],
      validationSchema: yup.object().shape({
        baseForm: yup.object().shape({
          contractNoId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0, t('Contract No. is required'))
            .required(t('Contract No. is required')),
          teamId: yup.array().nullable().min(1).required(t('Team is required')),
          districtId: yup.array().nullable().min(1).required(t('District is required')),
          workOrderId: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0)
            .required(t('Works Order No. is required')),
        }),
        lastSubmission: yup.boolean().required(t('Last submission is required')),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_HWR_REVIEW, FormStatusEnum.FORM_HWR_NOT_ENDORSED],
      validationSchema: yup.object().shape({
        wsCheckedOnSite: yup.boolean().required(t('WS Checked on Site is required')),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_HWR_ENDORSEMENT],
      validationSchema: yup.object().shape({
        iowChecked: yup.boolean().required(t('Checked at least 25% of HWR is required')),
        iowCheckedOnSite: yup.boolean().required(t('SIOW/IOW/AIOW Checked on Site is required')),
      }),
    },
    {
      formStatusList: [FormStatusEnum.FORM_HWR_APPROVED, FormStatusEnum.FORM_HWR_COUNTERSIGNED],
      validationSchema: yup.object(),
    },
  ]

  const { control, watch, setValue, getValues, reset, trigger } = useForm<FormHWRModel>({
    defaultValues: { ...InitHWRForm },
    resolver: (data, context, options) => {
      const validatorSchema = getFormValidationSchema(
        data.baseForm.formStatus,
        validationSchemaConditions,
      )
      return yupResolver(validatorSchema)(data, context, options)
    },
    mode: 'all',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
  })

  const [editPartC, setEditPartC] = useState<boolean>(false)
  const [formStatus, formPermission] = getValues(['baseForm.formStatus', 'baseForm.formPermission'])
  const [hwrGeneralOptions, setHwrGeneralOptions] = useState<HWRGeneralOptions>(
    globalState.formOptionsList?.find(({ key }) => key === 'HWR')?.value ?? {},
  )
  // const readOnly =
  //   formStatus === FormStatusEnum.FORM_HWR_REVIEW ||
  //   formStatus === FormStatusEnum.FORM_HWR_ENDORSEMENT ||
  //   formStatus === FormStatusEnum.FORM_HWR_NOT_ENDORSED ||
  //   formStatus === FormStatusEnum.FORM_HWR_APPROVED ||
  //   formStatus === FormStatusEnum.FORM_HWR_COUNTERSIGNED
  const readOnly =
    formStatus !== FormStatusEnum.FORM_HWR_DRAFT && formStatus !== FormStatusEnum.FORM_HWR_REJECTED

  const readOnlyIow =
    formStatus !== FormStatusEnum.FORM_HWR_REVIEW &&
    formStatus !== FormStatusEnum.FORM_HWR_NOT_ENDORSED

  const readOnlyWs = formStatus !== FormStatusEnum.FORM_HWR_ENDORSEMENT

  const reload = async () => {
    setRequest({
      callback: async () => {
        if (formId) {
          await FormHWRService.GetHwrForm(formId)
            .then(async (f) => {
              if (f) reset(f)

              if (f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId) {
                globalAction({
                  type: 'selectContract',
                  contractNoId: f.baseForm.contractNoId,
                })

                globalAction({
                  type: 'selectWorkGroup',
                  workGroupId: get(
                    find(globalState.contractList, { id: f.baseForm.contractNoId }),
                    'workGroups.0.id',
                    0,
                  ),
                })
              }

              globalAction({
                type: 'changeFormStatus',
                formStatus: f.baseForm.formStatusName,
              })
            })
            .catch((err) => {
              enqueueSnackbar(err.response.data.message, {
                variant: 'error',
                autoHideDuration: null,
              })
              NavigateTo(navigate, '/hidden-works-record/all-record')
            })
        }

        isMounted.current = true
      },
    })
  }

  useEffect(() => {
    if (!isMounted.current) {
      if (formState.form && formState.formType === 'HWR') {
        formStateAction({ type: 'pop', targetFormType: 'HWR' })

        reset({ ...formState.form })
      } else if (formId) {
        reload()
      } else {
        setValue('baseForm.contractNoId', storageContractId ? parseInt(storageContractId) : 0)
        setValue('baseForm.teamId', userInfo.teams)
        setValue('baseForm.districtId', userInfo.districts)
      }

      if (
        formStatus !== FormStatusEnum.FORM_HWR_DRAFT &&
        formStatus !== FormStatusEnum.FORM_HWR_APPROVED
      ) {
        setEditPartC(true) //temporary - delete later
      }

      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })
    }

    return () => {
      // formStateAction({ type: 'clear' })
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      })
    }
  }, [formId, formStateAction, globalAction])

  const partA: FormPartComponents = {
    partTitle: t('General Information'),
    component: (
      <HWRPartA
        globalState={globalState}
        control={control}
        userInfo={userInfo}
        watch={watch}
        setValue={setValue}
        formStatus={formStatus}
        getOptionsByKey={getOptionsByKey}
        getValues={getValues}
        formPermission={formPermission}
        readOnly={readOnly}
        options={hwrGeneralOptions}
        useWatch={useWatch}
      />
    ),
  }

  const partB: FormPartComponents = {
    partTitle: t('Hidden Works Details'),
    component: <HWRPartB control={control} readOnly={readOnly} />,
  }

  const partC: FormPartComponents = {
    partTitle: t('Hidden Works Review'),
    component: (
      <HWRPartC
        control={control}
        useWatch={useWatch}
        readOnlyWs={readOnlyWs}
        readOnlyIow={readOnlyIow}
        options={hwrGeneralOptions}
      />
    ),
    disabled:
      formStatus === FormStatusEnum.FORM_HWR_DRAFT ||
      formStatus === FormStatusEnum.FORM_HWR_REJECTED,
  }

  const partD: FormPartComponents = {
    partTitle: t('Submission Confirmation'),
    component: (
      <HWRPartD
        control={control}
        userInfo={userInfo}
        getValues={getValues}
        globalState={globalState}
        formPermission={formPermission}
        handleOnComplete={() => handleOnComplete()}
        formStatus={formStatus}
      />
    ),
  }

  const handleOnSubmit = async (
    event: any,
    isReject: boolean,
    notifyList?: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => {
    if (!(await trigger())) return
    setValue('signatureBase64', signatureBase64)
    var ff = getValues()
    ff.signatureBase64 = signatureBase64
    ff.notifyUserList = notifyList
    ff.submissionComment = submissionComment
    if (isReject) {
      setRequest({
        callback: async () => {
          await FormHWRService.RejectHwrForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/hidden-works-record/all-record')
          })
        },
      })
    } else {
      setRequest({
        callback: async () => {
          console.log('form', ff)
          await FormHWRService.ApproveHwrForm(ff).then((resp) => {
            enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
            NavigateTo(navigate, '/hidden-works-record/all-record')
          })
        },
      })
    }
  }

  const onLoadHistoryList = async () => {
    var defaultNotifyList = await FormService.GetHistoryList(getValues('baseForm.formId'))
    return defaultNotifyList
  }

  const handleOnSave = async () => {
    setRequest({
      callback: async () => {
        await FormHWRService.SaveHwrForm(getValues()).then((resp) => {
          setValue('baseForm.formId', resp.formId)
          setValue('baseForm.formStatus', resp.formStatus)
          NavigateTo(navigate, '/hidden-works-record/:id', {
            id: resp.formId,
          })
          formId = resp.formId
          reload()
          enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
        })
      },
    })
  }

  const handleOnDelete = async () => {
    setRequest({
      callback: async () => {
        await FormHWRService.DeleteHwrForm(getValues())
          .then(() => {
            enqueueSnackbar(t('Record Archived'), { variant: 'success' })
            NavigateTo(navigate, '/hidden-works-record/all-record')
          })
          .catch((err) => {
            enqueueSnackbar(err.response.data.message, { variant: 'error' })
          })
      },
    })
  }

  const onLoadNotifyList = async (isReject: boolean) => {
    var defaultNotifyList = await FormService.GetNotifyList(
      'HWR',
      getValues('baseForm.formId'),
      isReject,
      getValues('baseForm.districtId'),
      getValues('baseForm.teamId'),
    )
    return defaultNotifyList
  }

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
    title: t('HIDDEN WORKS RECORD'),
    components: [partA, partB, partC, partD],
    formOnLoadNotifyList: onLoadNotifyList,
    formOnLoadCommentList: async () => {
      return await FormService.GetCommentList(getValues('baseForm.formId'))
    },
    formOnSubmitComment: async (comment) => {
      await FormService.SubmitComment(getValues('baseForm.formId'), comment)
    },
    formOnLoadFormHistoryList: onLoadHistoryList,
    formOnSubmit: handleOnSubmit,
    formOnDelete: handleOnDelete,
    formOnSave: handleOnSave,
    startStep: step,
    isLoading: isLoading || isLoadingOption,
    disableSave: !formPermission?.canUpdate, // can edit this form
    disableDelete: !formPermission?.canDelete, // can delete this form
    disableComment: getValues('baseForm.formId') ? false : true,
    approveRequired: formPermission?.workflowRequired, // can go to next stage
    endOfFlow: formPermission?.endOfFlow,
    isMounted: isMounted.current,
  })

  return (
    <Fragment>
      <FormSwitcher />
      <ValidationToast control={control} />
    </Fragment>
  )
}

export default HWRForm
