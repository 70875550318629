import ConvertorsService from '@services/convertors.service';
import createReport from 'docx-templates';
import moment from 'moment';
import { GetFormStatusId } from './getFormStatusId';
import { filter, find, findLast, first, get, last, map } from 'lodash';
import { FormStatusEnum } from '@services/model/form/form.model';
// import mammoth from 'mammoth';
// import html2pdf from "html2pdf.js";

const postTemplateData = (templatePath: string, data: any, data2: any, optionList?: any) => {
  const  { globalState, dnGeneralOptions, nfGeneralOptions} = optionList
  switch(templatePath){
    case '/templates/NF_ver2.docx':
    case '/templates/NF_ver2_draft.docx':
      let formDn  = data['formDn']?.filter(dn => dn.baseForm.formStatusId === 64)?.map((x, index) => {
        return {
          itemNo: index + 1,
          dnNo: x.dnNo,
          typeOfNonCompliance: dnGeneralOptions?.typeOfNonComplianceList?.find(dn => dn.id === x.nonComplianceId)?.typeOfNonCompliance,
          amountOfDeduction: dnGeneralOptions?.typeOfNonComplianceList?.find(dn => dn.id === x.nonComplianceId)?.amountOfDeduction,
          ncPeriod: moment(x.startAt).format(moment.defaultFormat).toString() + " - " + moment(x.endAt).format(moment.defaultFormat).toString()
        }
      })

      let partC_action =  data['baseForm']['formStatusHistories']?.findLast((x) => GetFormStatusId(globalState?.formStatusList, ["FORM_NF_DRAFT"])?.includes(x.formStatusId))
      let partC2_action =  data['baseForm']['formStatusHistories']?.findLast((x) => GetFormStatusId(globalState?.formStatusList, ["FORM_NF_REVIEW"])?.includes(x.formStatusId))
      let partD_action =  data['baseForm']['formStatusHistories']?.findLast((x) => GetFormStatusId(globalState?.formStatusList, ["FORM_NF_RECTIFICATION", "FORM_NF_RECTIFICATION_REJECTED"])?.includes(x.formStatusId))
      let partE_action =  data['baseForm']['formStatusHistories']?.findLast((x) => GetFormStatusId(globalState?.formStatusList, ["FORM_NF_REVIEW_RECTIFICATION"])?.includes(x.formStatusId))

      return {
        contractor: globalState?.contractList?.find(x => x.id === data['baseForm']['contractNoId'])?.contractor ?? "",
        contractTitle: globalState?.contractList?.find(x => x.id === data['baseForm']['contractNoId'])?.contractTitle ?? "",
        NF_no: data['nfNo'] ?? "",
        contract_no: globalState?.contractList?.find(x => x.id === data['baseForm']['contractNoId'])?.contractNo ?? "",
        EI_no: data['eiNo'] ?? "N/A",
        ei_date_time: data['dateOfCheck'] === undefined ? "" : moment(data['dateOfCheck']).format(moment.defaultFormat).toString() ?? "",   //*** */
        locationAddress: data['location']['locationAddress']  ?? "",
        locationRemarks: data['locationRemark'] ?? "",
        ei_emergency_no: data2?(data2['emergencySerialNo'] ?? ""):"N/A",
        ei_icc: data2?(data2['iccNo'] ?? ""):"N/A",
        works_order_no: data['baseForm']['workOrderNo'] ?? '' ?? "",

        ei_works_order_no: data2?(data2['baseForm']['workOrderNo'] ?? '' ?? ""):"",

        complaint_checkbox: data2?(data2['caseSourceId'] === 33 ? '☑': '☐'):'☐',
        works_order_checkbox: data2?(data2['caseSourceId'] === 34 ? '☑': '☐'):'☐',
        staff_checkbox: data2?(data2['caseSourceId'] === 35 ? '☑': '☐'):'☐',
        others_checkbox: data2?(data2['caseSourceId'] === 36 ? '☑': '☐'):'☐',

        defect_category: nfGeneralOptions?.defectCategory?.find((x) => x.id === data['defectCategoryId'])?.name ?? "",
        identified_defect: nfGeneralOptions?.defectCategory?.find((category) => data['defectCategoryId'] === category.id)?.defectOptions?.find((x) => x.id === data['defectOptionId'])?.description ?? '' ?? "",
        date_of_check: data['dateOfCheck'] === undefined ? "" : moment(data['dateOfCheck']).format(moment.defaultFormat).toString() ?? "",
        time_limit: nfGeneralOptions?.defectCategory?.find((category) => data['defectCategoryId'] === category.id)?.defectOptions?.find((option) => data['defectOptionId'] === option.id)?.timeLimits?.find((x) => x.id === data['timeLimitId'])?.timeLimit ?? '' ?? "",
        nf_partA_remarks: data['remarks'] ?? "",
        ei_otherInfo: data2?(data2['otherInfo'] ?? ""):"N/A",
        due_date: data['dueDate'] === undefined ? "" :  moment(data['dueDate']).format(moment.defaultFormat).toString() ?? "",
        
        partC_full_name: globalState.userMetaFullList?.find((x) => x.userId === partC_action?.actionBy)?.userName ?? "",
        partC_designation: globalState.userMetaFullList?.find((x) => x.userId === partC_action?.actionBy)?.position ?? "",
        partC_datetime: partC_action === undefined ? "" :  moment(partC_action?.actionAt).format(moment.defaultFormat).toString() ?? "",

        partC2_full_name: globalState.userMetaFullList?.find((x) => x.userId === partC2_action?.actionBy)?.userName ?? "",
        partC2_designation: globalState.userMetaFullList?.find((x) => x.userId === partC2_action?.actionBy)?.position ?? "",
        partC2_datetime: partC2_action === undefined ? "" :   moment(partC2_action?.actionAt).format(moment.defaultFormat).toString() ?? "",
        rectification_date: data['rectifications']?.at(-1)?.rectificationDate === undefined ? "" : moment(data['rectifications']?.at(-1)?.rectificationDate).format(moment.defaultFormat).toString() ?? "",
       
        partD_remarks: data['rectifications']?.at(-1)?.otherInfo ?? "",
        partD_full_name: globalState.userMetaFullList?.find((x) => x.userId === partD_action?.actionBy)?.userName ?? "",
        partD_designation: globalState.userMetaFullList?.findLast((x) => x.userId === partD_action?.actionBy)?.position ?? "",
        partD_datetime: partD_action === undefined ? "" : moment(partD_action?.actionAt).format(moment.defaultFormat).toString() ?? "",
        
        partE_full_name: globalState.userMetaFullList?.find((x) => x.userId === partE_action?.actionBy)?.userName ?? "",
        partE_designation: globalState.userMetaFullList?.findLast((x) => x.userId === partE_action?.actionBy)?.position ?? "",
        partE_datetime: partE_action === undefined ? "" :  moment(partE_action?.actionAt).format(moment.defaultFormat).toString() ?? "" ,
        defect_photo: data['images'] === null ? [] : data['images'] ?? [],
        rectification_photo : data['rectifications']?.at(-1)?.['rectificationImages'] ?? [],
        formDn: formDn ?? [],
        defect_map: data["locationMapBase64"] ?? "",
        site_condition_photo: data2?(data2['siteImages'] === null ? [] : data2['siteImages'] ?? []):[]
        //ei_map: data2?(data2["locationMapBase64"] === null ? "":data2["locationMapBase64"]):"",

      }
    case '/templates/DN_template.docx':
      const draft = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_DRAFT])?.includes(formStatusId) })       
      const review = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_REVIEW])?.includes(formStatusId) })       
      const appealed = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_APPEALED])?.includes(formStatusId) })       
      const appealedEndorsed = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_APPEAL_ENDORSED])?.includes(formStatusId) })       
      const rectitication = findLast(get(data, 'baseForm.formStatusHistories'), ({formStatusId}) => { return GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_NF_RECTIFICATION])?.includes(formStatusId) })       
      
      const res =  {
        contractor: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractor', '') ,
        contract_title: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractTitle', ''),
        contract_no: get(find(globalState?.contractList, { 'id': get(data, 'baseForm.contractNoId') }), 'contractNo', ''),
        works_order_no: get(data, 'baseForm.workOrderNo', '') ,
        NF_no: get(data, 'parentNf.nfNo', ''),
        draftBy: get(find(globalState.userMetaFullList, { 'userId': get(draft, 'actionBy')}) , "userName",  ""),
        draft_datetime: get(draft, 'actionAt') ? moment(get(draft, 'actionAt')).format(moment.defaultFormat).toString() ?? ""  : "",
        review_signature: get(review, 'actionSignatureBase64', ''),
        review_datetime: get(review, 'actionAt') ? moment(get(review, 'actionAt')).format(moment.defaultFormat).toString() ?? ""  : "",
        appealed_signature: get(appealed, 'actionSignatureBase64', ''),
        appealed_datetime: get(appealed, 'actionAt') ? moment(get(appealed, 'actionAt')).format(moment.defaultFormat).toString() ?? ""  : "",
        appealedBy: get(find(globalState.userMetaFullList, { 'userId': get(appealed, 'actionBy')}) , "userName",  ""),
        appealedEndorsed_signature: get(appealedEndorsed, 'actionSignatureBase64', ''),
        appealedEndorsed_datetime: get(appealedEndorsed, 'actionAt') ? moment(get(appealedEndorsed, 'actionAt')).format(moment.defaultFormat).toString() ?? ""  : "",
        rectification_datetime: get(rectitication, 'actionAt') ? moment(get(rectitication, 'actionAt')).format(moment.defaultFormat).toString() ?? ""  : "",
        reference: '',
        deduction_amount: get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': get( data, 'nonComplianceId', '')}), 'amountOfDeduction'),
        road: get(data, 'parentNf.roadName', get(data, 'parentDna.roadName', '')),
        nc_startAt: get(data, 'startAt') ? moment(get(appealed, 'startAt')).format(moment.defaultFormat).toString() ?? ""  : "",
        checking_datetime: get(data, 'parentNf.dateOfCheck', get(data, 'parentDna.dateOfCheck', '')) ? moment(get(data, 'parentNf.dateOfCheck', get(data, 'parentDna.dateOfCheck', ''))).format(moment.defaultFormat).toString() ?? ""  : "",
        sor_item: get(data, 'sorItem', ''),
        formDn: filter(get(data, 'parentForm.formDn'), (dn) => { return map(get(dn, 'baseForm.formStatusHistories'), 'formStatusId').includes(first(GetFormStatusId(globalState?.formStatusList, [FormStatusEnum.FORM_DN_ISSUED])))}).map((dn) => ({
          dnNo: get(dn, 'dnNo'),
          typeOfNonCompliance: get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'typeOfNonCompliance'),
          amountOfDeduction: get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'amountOfDeduction'),
          refundableAmount: get(dn, 'baseForm.formStatus') === FormStatusEnum.FORM_DN_APPEAL_SUCCESS ? get(find(dnGeneralOptions?.typeOfNonComplianceList, { 'id': dn.nonComplianceId}), 'amountOfDeduction') : ""
        }))
      }

      console.log("res", res)
      return res
    case '/templates/testing.docx':
      return {
        name: 'name' ,
        surname: 'surname',
        checkbox: data['eiNo'] === "N/A" ? '☑': '☐',
        items: data['images']
      }
  }
}

const DocumentExporter = async (templatePath: string, reportName: String, data: any, data2: any, optionList?: any, additionalJsContext?: Object) => {
  const template = await getTemplate(templatePath); 
  try {
    const report = await createReport({
      // @ts-ignore
      template, 
      data: postTemplateData(templatePath, data,  data2, optionList),
      additionalJsContext: additionalJsContext
    });

    console.log("report", report)
    await saveDataToFile(
      report,
      reportName + '.pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    );
  } catch (err) {
    console.error(err)
    return err
  }

  /* Below code is genreating pdf but in a broken format*/ 
  // let htmlFormat;
  // await mammoth.convertToHtml({ arrayBuffer: report.buffer })
  //   .then(result => htmlFormat = result.value)

  // htmlFormat = htmlFormat.replace("img", "img width='400px' height='auto' ")

  // let opt = {
  //   margin:       1,
  //   filename:     'myfile.pdf',
  //   image:        { type: 'jpeg', quality: 0.98 },
  //   html2canvas:  { scale: 2 },
  //   jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' },
  //   pagebreak:    { mode: ['avoid-all', 'css', 'legacy'], before: ['#page2el', 'img'] }
  // };
  // console.log(html2pdf().set(opt).from(htmlFormat).save())

};

async function getTemplate(dataPath): Promise<Blob> {
    const request = await fetch(dataPath);
    const defaultTemplate = await request.blob();
    return defaultTemplate;
  }

// Load the user-provided file into an ArrayBuffer
const readFileIntoArrayBuffer = (fd): (Promise<any>) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(fd);
  });

const saveDataToFile = async (data, fileName, mimeType) => {
    const blob = new Blob([data], { type: mimeType });

    const formData = new FormData();
    formData.append("file", blob);
    const pdfBlob = await ConvertorsService.ConvertFiles(formData)

    const url = window.URL.createObjectURL(pdfBlob);
    console.log(url)
    console.log(fileName)
    downloadURL(url, fileName, mimeType);
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 1000);
  };

const downloadURL = (data, fileName, mimeType) => {
    const a = document.createElement('a');
    a.href = data;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

export { DocumentExporter }