import { useContext, useEffect, useRef }                                                          from 'react'
import { Alert, AlertTitle, Box, IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation }                                                                         from 'react-i18next'
import { InitUserInfo, UserInfo }                                                                 from '@services/model/user.model'
import useLocalStorage                                                                            from '@hooks/useLocalStorage'
import SelectContract                                                                             from '@components/sidebar/selectContract'
import { GlobalContext }                                                                          from '@providers/globalStore'
import useAPIFetch                                                                                from '@hooks/useAPIFetch'
import ContractService                                                                            from '@services/contract.service'
import { initOptions }                                                                            from '@utils/reloadOptions'
import { useNavigate }                                                                            from 'react-router-dom'
import { useKeycloak }                                                                            from '@react-keycloak/web'
import highwayfull                                                                                from '../../assets/img/HydLogo-ec.png'
import DialogButton                                                                               from '@components/button/DialogButton'
import LoadingPopup                                                                               from '@components/form/LoadingDialog'

const HeaderTitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: 45,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 'bold',
  marginTop: '0px',
}))

const HeaderSubtitle = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    fontSize: 27,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 24,
    margin: 8,
  },
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: '0px',
  fontWeight: 'bold',
}))

export default function SetupPage() {
  const isMounted = useRef(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { keycloak, initialized } = useKeycloak()
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const { state, dispatch } = useContext(GlobalContext)
  const { isInitiated } = state
  const [loginUser, setLoginUser] = useLocalStorage<UserInfo | undefined>('usr', undefined)
  const [lsContractNoId, setContractNoId] = useLocalStorage<string | undefined>(
    'contract',
    undefined,
  )
  const [lsWorkGroupId, setWorkGroupId] = useLocalStorage<string | number | undefined>(
    'workgroup',
    undefined,
  )

  const { setRequest, isLoading } = useAPIFetch()
  const { setRequest: setContractRequest, isLoading: isContractLoading } = useAPIFetch()

  useEffect(() => {
    if (!!loginUser && !!loginUser.uuid && !isInitiated) {
      setContractRequest({
        callback: async () => {
          const contract = await ContractService.GetContractList()
          dispatch({
            type: 'reloadContractList',
            list: contract,
          })
          console.debug('mainLayout current contract', contract)
        },
      })

      setRequest({
        callback: async (cancelToken: any) => {
          const res = await initOptions()
          Object.entries(res).forEach(([type, list = []]: [any, any[]]) => {
            dispatch({ type, list })
          })
          console.info('[GlobalContext - state] initOptions', state)
        },
      })

      dispatch({ type: 'setInitiazed' })
    }
    if (isInitiated && lsContractNoId) {
      dispatch({
        type: 'selectContract',
        contractNoId: Number(lsContractNoId),
      })
    }
    if (lsWorkGroupId && lsContractNoId) {
      dispatch({
        type: 'selectWorkGroup',
        workGroupId: Number(lsWorkGroupId),
      })
    }
    isMounted.current = true
  }, [loginUser])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        justifyContent: 'center',
        padding: matchDownMd ? '10px 10px' : '57px 80px',
      }}>
      <LoadingPopup open={isLoading || isContractLoading} />

      <Stack direction='row' spacing={ 2 }>
        <Box component='img'
             alt='Highway'
             src={ highwayfull }
             sx={ { marginY: '24px', width: matchDownMd ? '90%' : '25%' } } />
        {/* <Alert severity='info' sx={ { width: '100%' } }>
          <AlertTitle>
            <Box sx={ { fontWeight: 'bold' } }>Notice</Box>
          </AlertTitle>
          <Typography paragraph>
            We kindly request that users with contracts
            <Typography component='span'
                        sx={ { fontWeight: 'bold', color: 'red' } }> 07/HY/2021</Typography>,
            <Typography component='span'
                        sx={ { fontWeight: 'bold', color: 'red' } }> 18/HY/2020</Typography> and  <Typography component='span'
                        sx={ { fontWeight: 'bold', color: 'red' } }> 12/HY/2019</Typography> visit the RMMS production platform via the following URL to continue the work.<br />
            <a href='https://rmms.hk'>https://rmms.hk</a><br />
            <br />
            For all other contract users, please select your respective contract and proceed with your work on the
            platform.<br />
            <br />
            Thank you for your cooperation.
          </Typography>
        </Alert> */}
      </Stack>

      <Box>
        <HeaderSubtitle hidden={matchDownMd}>{t('Welcome Back, ')}</HeaderSubtitle>
        <HeaderTitle hidden={matchDownMd}>{loginUser?.firstName}</HeaderTitle>
      </Box>
      <Box>
        <SelectContract />
      </Box>
      <Box sx={{ display: 'flex' }}>
        <DialogButton
          sx={{ m: 2, width: '100%' }}
          onClick={() => navigate('/landing')}
          disabled={!(lsContractNoId && lsWorkGroupId)}>
          Go to RMMS
        </DialogButton>
        <IconButton
          sx={{ p: 0 }}
          onClick={() => {
            sessionStorage.clear()
            setLoginUser({ ...InitUserInfo })
            localStorage.clear()
            navigate('/')
            keycloak.logout()
          }}>
          <img src="/img/logout.svg" alt="menu" />
        </IconButton>
      </Box>
    </Box>
  )
}
