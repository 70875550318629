import FormContainer from '@components/form/container'
import FormController from '@components/form/controller'
import FormField from '@components/form/field'
import { DNGeneralOptions, FormDNModel } from '@services/model/form/form.DN.model'
import { Control } from 'react-hook-form'
import FormSelect from '@components/form/select'
import uuid from 'react-uuid'
import { GetTeamOptions } from '@utils/teamOptions'
import { GetDistrictOptions } from '@utils/districtOptions'
import FormTypography from '@components/form/typography'
import FormMultipleSelect from '@components/form/multipleSelect'
import FormDateTimePicker from '@components/form/dateTimePicker'
import FormMediumMap from '@components/form/mediumMap'
import FormFreeText from '@components/form/freeText'
import { FormStatusEnum } from '@services/model/form/form.model'
import { ForwardedRef, forwardRef, useEffect, useState } from 'react'
import { UserInfo } from '@services/model/user.model'

const UUID = uuid()

function PartAInner(
  {
    control,
    getValues,
    useWatch,
    formStatus,
    dnGeneralOptions,
    globalState,
    hasParentForm,
    userInfo,
    isMounted,
  }: {
    control: Control<FormDNModel>
    getValues: any
    useWatch: any
    formStatus: string
    dnGeneralOptions?: DNGeneralOptions
    globalState: any
    hasParentForm: boolean
    userInfo: UserInfo
    isMounted: any
  },
  ref: ForwardedRef<HTMLUListElement>,
) {
  const contractNoList =
    globalState.contractList?.map((x) => {
      return {
        key: x.id,
        value: x.contractNo,
      }
    }) || []

  const readonly = formStatus !== FormStatusEnum.FORM_NF_DRAFT
  const [teamList, setTeamList] = useState(globalState.teamList)
  useEffect(() => {
    if (globalState.teamList) {
      setTeamList(globalState.teamList)
    }
  }, [globalState])
  console.debug('hasParentForm', hasParentForm, readonly, teamList, globalState)

  const AmountOfDeductionField = () => {
    const nonComplianceId = useWatch({
      name: 'nonComplianceId',
      control,
    })

    return (
      <FormField fieldName="Amount of Deduction">
        <FormTypography
          type="number"
          value={
            dnGeneralOptions?.typeOfNonComplianceList?.find((x) => x.id === nonComplianceId)
              ?.amountOfDeduction
          }
        />
      </FormField>
    )
  }

  const ClientContractorField = () => {
    const contractNoId = useWatch({
      name: 'baseForm.contractNoId',
      control,
    })
    const matchedContract = globalState?.contractList?.find(
      (contractNo) => contractNo.id === contractNoId,
    )

    return (
      <>
        <FormField fieldName="From">
          <FormTypography type="string" value={matchedContract?.client} />
        </FormField>
        <FormField fieldName="Contractor">
          <FormTypography type="string" value={matchedContract?.contractor} />
        </FormField>
      </>
    )
  }

  return (
    <FormContainer fkey={`${UUID}-dn-a`}>
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control: control,
            rules: { required: true },
          }}>
          <FormSelect fullWidth options={contractNoList} uneditable={true} />
        </FormController>
      </FormField>

      <FormField fieldName="Team">
        <FormController
          controllerProps={{
            name: 'baseForm.teamId',
            control,
            rules: { required: true },
          }}>
          <FormMultipleSelect
            options={hasParentForm || readonly ? teamList ?? [] : GetTeamOptions(userInfo.teams)}
            uneditable={hasParentForm || readonly}
          />
        </FormController>
      </FormField>

      <FormField fieldName="District">
        <FormController
          controllerProps={{
            name: 'baseForm.districtId',
            control,
            rules: { required: true },
          }}>
          <FormMultipleSelect
            options={
              hasParentForm || readonly
                ? globalState.districtList || []
                : GetDistrictOptions(userInfo.districts)
            }
            uneditable={hasParentForm || readonly}
          />
        </FormController>
      </FormField>

      <ClientContractorField />

      <FormField fieldName="NF No">
        <FormController
          controllerProps={{
            name: 'parentNf.nfNo',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>

      <FormField fieldName="Type of Non-Compliance">
        <FormController
          controllerProps={{
            name: 'nonComplianceId',
            control: control,
          }}>
          <FormSelect
            fullWidth
            options={
              dnGeneralOptions?.typeOfNonComplianceList?.map((x) => {
                return {
                  key: x.id,
                  value: x.dnRefNo + ' - ' + x.typeOfNonCompliance,
                }
              }) || []
            }
            uneditable={readonly}
          />
        </FormController>
      </FormField>

      {getValues('parentDna') ? (
        <FormField fieldName="DNA No">
          <FormController
            controllerProps={{
              name: 'parentDna.dnaNo',
              control,
            }}>
            <FormTypography />
          </FormController>
        </FormField>
      ) : (
        <FormField fieldName="DN No">
          <FormController
            controllerProps={{
              name: 'dnNo',
              control,
            }}>
            <FormTypography />
          </FormController>
        </FormField>
      )}

      <AmountOfDeductionField />

      <FormField fieldName="Works Order No.">
        <FormTypography value={getValues('baseForm.workOrderNo')} />
      </FormField>

      {getValues('parentDna') ? (
        <FormField fieldName="Date & Time of Checking">
          <FormController
            controllerProps={{
              name: 'parentDna.dateOfCheck',
              control,
              rules: { required: true },
            }}>
            <FormDateTimePicker type="datetime" uneditable={true} />
          </FormController>
        </FormField>
      ) : (
        <FormField fieldName="Date & Time of Checking">
          <FormController
            controllerProps={{
              name: 'parentNf.dateOfCheck',
              control,
              rules: { required: true },
            }}>
            <FormDateTimePicker type="datetime" uneditable={true} />
          </FormController>
        </FormField>
      )}

      <FormField fieldName="Start of NC Period">
        <FormController
          controllerProps={{
            name: 'startAt',
            control,
          }}>
          <FormDateTimePicker type="datetime" uneditable />
        </FormController>
      </FormField>

      <FormField fieldName="End of NC Period">
        <FormController
          controllerProps={{
            name: 'endAt',
            control,
          }}>
          <FormDateTimePicker type="datetime" uneditable />
        </FormController>
      </FormField>

      {getValues('parentDna') ? (
        <>
          <FormField fieldName="Location">
            <FormController
              controllerProps={{
                name: 'parentDna.location',
                control,
              }}>
              <FormMediumMap uneditable isMounted={isMounted.current} ref={ref} />
            </FormController>
          </FormField>

          <FormField fieldName="Road">
            <FormController
              controllerProps={{
                name: 'parentDna.roadName',
                control,
              }}>
              <FormTypography />
            </FormController>
          </FormField>
        </>
      ) : (
        <>
          <FormField fieldName="Location">
            <FormController
              controllerProps={{
                name: 'parentNf.location',
                control,
              }}>
              <FormMediumMap uneditable isMounted={isMounted.current} ref={ref} />
            </FormController>
          </FormField>

          <FormField fieldName="Road">
            <FormController
              controllerProps={{
                name: 'parentNf.roadName',
                control,
              }}>
              <FormTypography />
            </FormController>
          </FormField>
        </>
      )}

      <FormField fieldName="SOR Item No.">
        <FormController
          controllerProps={{
            name: 'sorItem',
            control,
          }}>
          <FormFreeText
            textfieldProps={{
              fullWidth: true,
              rows: 10,
              type: 'number',
              inputProps: {
                min: '0',
              },
            }}
            sx={{
              width: '90%',
            }}
            uneditable={readonly}
          />
        </FormController>
      </FormField>
    </FormContainer>
  )
}

export const PartA = forwardRef(PartAInner)
