import { KeyValPair } from '@models/common'
import { BaseFilterOptions, BaseListResponse } from '../base.model'
import { ContractNo } from '../contract.model'
import { FileModel } from '../file.model'
import {
  BaseFormStatusDtoForList,
  FormModel,
  FormStatusEnum,
  InitFormModel,
  InitFormStatusHistory,
} from './form.model'
import { FormScrpModel as FormScrPhotoModel } from './form.SCRP.model'

export interface FormScrModel {
  baseForm: FormModel

  // Part A
  cdrId?: number
  cdrRef?: boolean
  scrNo?: string
  itemNo?: number
  formDate?: Date
  worksOrderNoId?: number

  // Part B - Cdr
  fromScrCrw?: FormScrCrw

  // Part C
  worksStatusId?: number
  actualActivities?: string
  workInProgressTypeId?: number
  wipTypeSubOptionId?: number
  wipTypeSubOptionOthers?: string

  // Part D
  contractorResources: ScrContractorResource[]

  // Part E
  scrPhotos: FormScrPhotoModel[]

  // Part F
  workCompletionId?: number
  mosquitoBpId?: number
  loaWastesAbsentId?: number
  mpStockpiledId?: number

  lsgSatisfactionId?: number
  lsgReason?: string

  qowSatisfactionId?: number
  qowReason?: string

  emSatisfactionId?: number
  emReason?: string

  essentialOperation?: number[]
  essentialOperationOther?: string

  inSituTest?: number[]
  inSituTestOther?: string

  inSituSample?: number[]
  inSituSampleOther?: string

  // Part G - HW
  // hiddenWorkCheck?: boolean
  hiddenWorkRefs?: FileModel[]

  // Part H
  remarks?: string

  signatureRequired: boolean
  signatureBase64?: string
  notifyUserList?: string[]
  submissionComment?: string
  hiddenWorkCheck: boolean

  summaryMapBase64?: string
}

export interface ScrListResponse extends BaseListResponse {
  list: FormScrListModel[]
}

export interface ScrFilterOptions extends BaseFilterOptions {
  workOrderIdList: number[]
}

export interface ScrContractorResource {
  id: number
  description?: string
  number?: number
  status: boolean
  resourceTypeId?: number
  resourceTypeOthers?: string
}

export interface FormSCRHiddenWork {
  id: number
  status: boolean
  recordNo?: string
  contractNo?: string
  category?: string
  woNo?: string
}

export interface FormScrCrw {
  locationDetails?: string
  proposedActivities?: string
  noOfWorker?: number
  lsgOnSite?: boolean
  essentialOperation?: boolean
  hiddenWorkOtherThanLsg?: boolean
  inSituTest?: boolean
  dayNightWorks?: number
  iosWsRemarks?: string
}

export const InitSCRForm: FormScrModel = {
  baseForm: {
    ...InitFormModel,
    formStatus: FormStatusEnum.FORM_SCR_DRAFT,
    formStatusHistories: [{ ...InitFormStatusHistory, actionName: 'Draft' }],
  },

  signatureRequired: false,
  scrPhotos: [],
  contractorResources: [],
  hiddenWorkCheck: false,
}

export type ScrGeneralOptions = {
}

export interface FormScrListModel extends BaseFormStatusDtoForList {
  formId: number
  reportNo: string
  contractNo: string
  workOrderNo: string
  cdrNo: string
  location?: string
  inspectionDate?: Date
  worksStatus: string
  submittedBy: string
  approvedBy: string
  updatedAt: Date
}

export type CdrOptions = {
  workOrderList: {
    id: number
    workOrderNo: string
    districtId?: number
    typeOfWorks?: boolean
  }[]
  cdrList: {
    id: number
    cdrNo: string
    relatedWorkOrder: {
      workOrderId: number
      items: {
        id: number
        name: string
        locationDetails: string
        proposedActivities: string
        noOfWorker: number
        lsgOnSite: boolean
        essentialOperation: boolean
        hiddenWorkOtherThanLsg: boolean
        inSituTest: boolean
        dayNightWorks: number
        iosWsRemarks?: string
      }[]
    }[]
  }[]
}

export interface ScrDashboardReq {
  workOrderId?: {operator?: string, value?: (number | undefined)[] }
  districtId?: {operator?: string, value?: (number | undefined)[] }
  startDate?: Date
  dateInterval?: number
  columnNo?: number
}