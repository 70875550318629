import apiGateway from '@utils/axiosInterceptor'
import {
  FormHwRefModel,
  FormHwrListModel,
  FormHWRModel,
  HwrFilterOptions,
  HWRGeneralOptions,
  HwrListResponse,
} from '../model/form/form.HWR.model' //, FormHwrListModel, HWRGeneralOptions
import { BaseFormResponse } from '../model/form/form.model'
import FormService from './form.service'

const GetHwrForm = async (formId: string) => {
  return await apiGateway
    .get<FormHWRModel>(`api/v1/forms/HWR/${formId}`)
    .then((rep) => {
      return rep.data
    })
}

const GetGeneralOptions = async () => {
  return await apiGateway.get<HWRGeneralOptions>(`/api/v1/forms/HWR/options`).then((rep) => {
    return rep.data
  })
}

const GetHwrFilterOption = async (showMyRecord?:boolean) => {
  let api = `api/v1/forms/HWR/filter-options`
  if (showMyRecord) {
    api += '?my_action=true'
  }
  return await apiGateway.get<HwrFilterOptions>(api).then((rep) => {
    return rep.data
  })
}

const GetHwrList = async (req: object, cancelToken: any, myRecordOnly: boolean) => {
  return await apiGateway
    .post<HwrListResponse>(
      `api/v1/forms/HWR/${myRecordOnly ? 'my-action' : 'list'}`,
      req,
      { cancelToken: cancelToken },
    )
    .then((rep) => {
      return rep.data
    })
}

const GetHwRefList = async (workOrderId: number) => {
  //var workOrderId = req.baseForm.workOrderId
  return await apiGateway
    .get<FormHwRefModel>(`api/v1/forms/HWR/HWRef/${workOrderId}`)
    .then((rep) => {
      return rep.data
    })
}

const SaveHwrForm = async (req: FormHWRModel) => {
  if (req.baseForm.formId === '')
    return await apiGateway.post<BaseFormResponse>('api/v1/forms/HWR', req).then((rep) => {
      return rep.data
    })
  else {
    return await apiGateway
      .put<BaseFormResponse>(`api/v1/forms/HWR/${req.baseForm.formId}`, req)
      .then((rep) => {
        return rep.data
      })
  }
}

const ApproveHwrForm = async (req: FormHWRModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/HWR/${req.baseForm.formId ? req.baseForm.formId : '_'}/approve`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const RejectHwrForm = async (req: FormHWRModel) => {
  return await apiGateway
    .put<BaseFormResponse>(
      `api/v1/forms/HWR/${req.baseForm.formId ? req.baseForm.formId : '_'}/reject`,
      req,
    )
    .then((rep) => {
      return rep.data
    })
}

const DeleteHwrForm = async (req: FormHWRModel) => {
  return await apiGateway.delete<BaseFormResponse>(
    `api/v1/forms/HWR/${req.baseForm.formId}`,
  )
}

const FormHwrService = {
  GetHwrForm,
  GetHwrList,
  GetHwrFilterOption,
  SaveHwrForm,
  ApproveHwrForm,
  RejectHwrForm,
  DeleteHwrForm,
  GetGeneralOptions,
  GetHwRefList,
}

export default FormHwrService
