import { useContext, useEffect, useRef, useState } from 'react'
import { useForm, useWatch }                       from 'react-hook-form'
import { useTranslation }                          from 'react-i18next'
import { useNavigate, useParams }                  from 'react-router-dom'
import uuid                                        from 'react-uuid'
import * as yup                                    from 'yup'
import axios                                       from 'axios'
import { find, get }                               from 'lodash'
import FormContainer                               from '@components/form/container'
import FormSignatureHistories                      from '@components/form/signatureHistories'
import ValidationToast                             from '@components/form/ValidationToast'
import { DevTool }                                 from '@hookform/devtools'
import { yupResolver }                             from '@hookform/resolvers/yup'
import useAPIFetch                                 from '@hooks/useAPIFetch'
import useFormSwitcher                             from '@hooks/useFormSwitcher'
import useGeneralOptions                           from '@hooks/useGeneralOptions_to_be_used'
import useLargeMap                                 from '@hooks/useLargeMap'
import useQuery                                    from '@hooks/useQuery'
import { FormPartComponents }                      from '@models/common'
import { FormContext }                             from '@providers/formStateProvider'
import { GlobalContext }                           from '@providers/globalStore'
import FormService                                 from '@services/form.service'
import FormEaService                               from '@services/formService/form.EA.service'
import { ContractNo, WorkOrder }                   from '@services/model/contract.model'
import { EaCategory, FormEaModel, InitEAForm }     from '@services/model/form/form.EA.model'
import { FormStatusEnum }                          from '@services/model/form/form.model'
import getFormValidationSchema                     from '@utils/formValidationSchema'
import { NavigateTo }                              from '@utils/navigate'
import { QueryStepParser }                         from '@utils/queryStepParser'
import { useSnackbar }                             from 'notistack'
import PartA                                       from './part-a'
import PartB                                       from './part-b'
import PartC                                       from './part-c'
import PartD                                       from './part-d'
import PartE                                       from './part-e'

const UUID = uuid()

export default function EAForm() {
  const { state: globalState, userInfo, dispatch: globalAction } = useContext(GlobalContext)
  const { state: formState, dispatch: formStateAction }          = useContext(FormContext)
  const { getOptionsByKey }                                      = useGeneralOptions()

  const navigate = useNavigate()
  const { t }    = useTranslation()

  // extracting id from params
  let { id: formId } = useParams<string>()

  // extracting step from query
  const query = useQuery()
  const step  = QueryStepParser(query.get('step'))

    const validationSchemaConditions = [
        {
            formStatusList  : [FormStatusEnum.FORM_EA_DRAFT],
            validationSchema: yup.object().shape({
                                                     planAreaOfStructure      : yup.number()
                                                                                   .when('formNea.typeOfMmWorksValue', {
                                                                                       is  : (typeOfMmWorksValue: string) => {
                                                                                           const mmWorksValue = getOptionsByKey('Form EA - Category')?.find(x => x.key === typeOfMmWorksValue)?.value ?? ''

                                                                                           return mmWorksValue === 'Conduct Inspections of Highway Structures and Undertake Structural Maintenance' ||
                                                                                                  mmWorksValue === 'Conduct Inspections on Highway Structures and Undertake Structural Maintenance'
                                                                                       },
                                                                                       then: yup.number().required(t('Plan Area of Structure is required'))
                                                                                   }),
                                                     viableToConductInspection: yup.boolean().required(t('Viable to Conduct Inspection is required')),
                                                     actualCheckingDate       : yup.date().required(t('Actual Date & Time of Checking is required'))
                                                 })
        },
        {
            formStatusList  : [FormStatusEnum.FORM_EA_AUDIT_UNAVAILABLE,
                               FormStatusEnum.FORM_EA_AGREE_PRELIM_RESULT,
                               FormStatusEnum.FORM_EA_REVIEW_PRELIM_RESULT,
                               FormStatusEnum.FORM_EA_ENDORSE_PRELIM_RESULT,
                               FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED,
                               FormStatusEnum.FORM_EA_AUDIT_RESULT_ISSUED,
                               FormStatusEnum.FORM_EA_AUDIT_RESULT_UPDATED,
                               FormStatusEnum.FORM_EA_REVIEW_AUDIT_APPEAL,
                               FormStatusEnum.FORM_EA_ENDORSE_AUDIT_APPEAL,
                               FormStatusEnum.FORM_EA_AUDIT_APPEAL_NOT_ENDORSED,
                               FormStatusEnum.FORM_EA_AUDIT_RESULT_CONFIRMED],
            validationSchema: yup.object()
        }
    ]

    const isMounted                                               = useRef<boolean>(false)
    const { control, watch, setValue, getValues, reset, trigger } = useForm<FormEaModel>({
                                                                                             defaultValues : { ...InitEAForm },
                                                                                             resolver      : (data, context, options) => {
                                                                                                 const validatorSchema = getFormValidationSchema(data.baseForm.formStatus,
                                                                                                                                                 validationSchemaConditions)

                                                                                                 return yupResolver(validatorSchema)(data, context, options)
                                                                                             },
                                                                                             mode          : 'all',
                                                                                             reValidateMode: 'onChange',
                                                                                             criteriaMode  : 'all'
                                                                                         })

  const formPermission                                               = getValues('baseForm.formPermission')
  const { setRequest, isLoading }                                    = useAPIFetch()
  const { setRequest: setOptionRequest, isLoading: isLoadingOption } = useAPIFetch()
  const [eaCategoryList, setEaCategoryList]                          = useState<EaCategory[]>()

  const { enqueueSnackbar } = useSnackbar()

  const reload = () => {
    setRequest({
      callback: async () => {
        // If parent form > child form > back to parent form, ignore fetch the form data
        if (formId) {
          try {
            const f = await FormEaService.GetEaForm(formId)
            if (f) reset(f)
            f.defective = f.defective === undefined ? false : f.defective

            if (globalState.worksOrderList) setWorkOrderList(globalState.worksOrderList)

            const contractInfo = find(globalState.contractList, { id: f.baseForm.contractNoId })
            if (contractInfo) setContractInfo(contractInfo)

            if (f.defectiveList && f.defectiveList.length > 0)
              setValue('defectiveList', f.defectiveList)
            else
              setValue(
                  'defectiveList',
                  Array.from(Array(f.totalInspectionSection), (e, i) => ({
                    sectionNo           : i + 1,
                    available           : 'Y' as const,
                    noOfDefectIdentified: 0,
                    defective           : 'N' as const,
                    status              : true, // MUST BE TRUE TO SHOW IT IN THE TABLE
                  })),
              )

            if (f.baseForm.contractNoId && f.baseForm.contractNoId !== globalState.contractNoId) {
              globalAction({
                             type        : 'selectContract',
                             contractNoId: f.baseForm.contractNoId,
                           })

              globalAction({
                             type       : 'selectWorkGroup',
                             workGroupId: get(
                                 find(globalState.contractList, { id: f.baseForm.contractNoId }),
                                 'workGroups.0.id',
                                 0,
                             ),
                           })
            }

            globalAction({
                           type      : 'changeFormStatus',
                           formStatus: f.baseForm.formStatusName,
                         })
          }
          catch (err) {
            enqueueSnackbar(axios.isAxiosError(err) ? err.response?.data.message : 'Internal server error', {
              variant         : 'error',
              autoHideDuration: null,
            })
            NavigateTo(navigate, '/engineer-audit/all-record')
          }
        }
      },
    })
  }

  const [workOrderList, setWorkOrderList] = useState<WorkOrder[]>()

  const [contractInfo, setContractInfo] = useState<ContractNo>()

  const reloadOthers = async () => {
    setOptionRequest({
      callback: async () => {
        await FormEaService.GetEaCategory().then(async (list) => {
          if (list) setEaCategoryList(list)
        })
      },
    })
  }

  useEffect(() => {
    if (!isMounted.current && !eaCategoryList) {
      isMounted.current = true
      if (formState.form && formState.formType === 'EA') {
        // s1: child to parent , set form to formstate in global context value
        // previous editing form (new / existed form)
        reset({ ...formState.form })
      } else if (formId) {
        //is old form, get db values path formId , db form
        reload()
      } else {
        //new form
        reset({ ...InitEAForm })
      }

      reloadOthers()

      globalAction({
        type: 'changeFormStatus',
        formStatus: getValues('baseForm.formStatusName'),
      })

      // add staus to nav bar
    }

    // pop off form stack (for child form: DEA)
    return () => {
      formStateAction({ type: 'pop', targetFormType: 'EA' }) // if there is a child form , pop itself from the global form stack
      globalAction({
        type: 'changeFormStatus',
        formStatus: undefined,
      }) // nav bar remove status
    }
  }, [formId])

  const [formStatus] = watch(['baseForm.formStatus'])
  const mmWorksValue =
    getOptionsByKey('Form EA - Category')?.find((x) => x.key === getValues('formNea.typeOfMmWorks'))
      ?.value ?? ''

  //#region form parts

  const EAPartA = (): FormPartComponents => {
    return {
      partTitle: t('General Information'),
      component: (
          <PartA control={ control }
                 formStatus={ formStatus }
                 getValues={ getValues }
                 globalState={ globalState }
                 contractInfo={ contractInfo } />
      )
    }
  }

  const EAPartB = (): FormPartComponents => {
    const [LargeMap, exportCanvas] = useLargeMap({
      value: getValues('formDeaList'),
      onMarkerClick: (e: any, markerIndex: number) => {
        handleLinkClick(markerIndex)
      },
    })

    const handleLinkClick = (index: number) => {
      formStateAction({ type: 'push', form: getValues(), formType: 'EA' })
      NavigateTo(
        navigate,
        '/defect-identified-in-engineer-audit/:formId',
        { formId },
        { num: index },
      )
    }

    const handleAddPhotoClick = () => {
      formStateAction({ type: 'push', form: getValues(), formType: 'EA' })
      NavigateTo(navigate, '/defect-identified-in-engineer-audit/:formId', { formId })
    }

    const saveTargetMap = async () => {
      let targetBase64 = await exportCanvas()
      setValue('summaryMapBase64', targetBase64)
    }

    return {
      partTitle: t('Defect Identified'),
      component: (
        <PartB
          control={control}
          formStatus={formStatus}
          getValues={getValues}
          globalState={globalState}
          handleLinkClick={handleLinkClick}
          handleAddPhotoClick={handleAddPhotoClick}
          LargeMap={LargeMap}
        />
      ),
      onBack: async () => {
        const contractInfo = find(globalState.contractList, { id: getValues('baseForm.contractNoId') })
        if (contractInfo)
          setContractInfo(contractInfo)
      },
      onNext: () => {
        const contractInfo = find(globalState.contractList, { id: getValues('baseForm.contractNoId') })
        if (contractInfo)
          setContractInfo(contractInfo)

        const formDeas               = getValues('formDeaList')
        const defectiveList          = getValues('defectiveList')
        const totalInspectionSection = getValues('totalInspectionSection')

        if (defectiveList && totalInspectionSection) {
          const inspectionSection = Array<number>(totalInspectionSection).fill(0)
          formDeas.forEach(formDea => {
            if (formDea.inspectionSection !== undefined)
              inspectionSection[formDea.inspectionSection - 1]++
          })

          defectiveList.forEach((defect, index) => {
            const noOfDefect = inspectionSection[index]

            if (noOfDefect > 0) {
              defect.defective            = 'Y'
              defect.noOfDefectIdentified = noOfDefect
            }
          })

          setValue('defectiveList', defectiveList)
        }

        return saveTargetMap()
      }
    }
  }

  const EAPartC = (): FormPartComponents => {
    return {
      partTitle: t('Site Condition'),
      component: <PartC control={ control } formStatus={ formStatus } />
    }
  }

  const EAPartD = (): FormPartComponents => {
    return {
      partTitle: t('Audit Result'),
      component: (
          <PartD control={ control }
                 formStatus={ formStatus }
                 getValues={ getValues }
                 setValue={ setValue }
                 eaCategoryList={ eaCategoryList }
                 contractInfo={ contractInfo } />
      )
    }
  }

  const EAPartE = (): FormPartComponents => {
    return {
      partTitle: t('Appeal For Engineer Audit'),
      component: (
          <PartE control={ control }
                 formStatus={ formStatus }
                 getValues={ getValues }
                 globalState={ globalState }
                 setValue={ setValue }
                 useWatch={ useWatch } />
      ),
      disabled :
          formStatus === FormStatusEnum.FORM_EA_DRAFT ||
          formStatus === FormStatusEnum.FORM_EA_AGREE_PRELIM_RESULT ||
          formStatus === FormStatusEnum.FORM_EA_REVIEW_PRELIM_RESULT ||
          formStatus === FormStatusEnum.FORM_EA_ENDORSE_PRELIM_RESULT ||
          formStatus === FormStatusEnum.FORM_EA_AUDIT_UNAVAILABLE ||
          formStatus === FormStatusEnum.FORM_EA_PRELIM_RESULT_REJECTED
    }
  }

  const EAPartF = (): FormPartComponents => {
    return {
      partTitle: t('Submission Confirmation'),
      component: (
          <FormContainer fkey={ `${UUID}-ea-f` }>
            <FormSignatureHistories formStatusList={ globalState.formStatusList }
                                    histories={ getValues('baseForm.formStatusHistories') }
                                    control={ control }
                                    handleOnComplete={ () => handleOnComplete() }
                                    endOfFlow={ formPermission?.endOfFlow }
                                    userMetaList={ globalState.userMetaList }
                                    roleMetaList={ globalState.roleMetaList }
                                    disabled={ !formPermission?.canUpdate } />
          </FormContainer>
      )
    }
  }

  //#endregion

  const handleOnSubmit = async (event: any,
                                isReject: boolean,
                                notifyList?: string[],
                                signatureBase64?: string,
                                submissionComment?: string) => {
    if (!(await trigger())) return
    setValue('signatureBase64', signatureBase64)

    var ff               = getValues()
    ff.signatureBase64   = signatureBase64
    ff.notifyUserList    = notifyList
    ff.submissionComment = submissionComment

    if (isReject)
      setRequest({
                   callback: async () => {
                     await FormEaService.RejectEaForm(ff).then((resp) => {
                       enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
                       NavigateTo(navigate, '/engineer-audit/all-record')
                     })
                   }
                 })
    else
      setRequest({
                   callback: async () => {
                     await FormEaService.ApproveEaForm(ff).then((resp) => {
                       enqueueSnackbar(t('Record Submitted'), { variant: 'success' })
                       NavigateTo(navigate, '/engineer-audit/all-record')
                     })
                   }
                 })
  }

  const handleOnSave = async () =>
      setRequest({
                   callback: async () => {
                     await FormEaService.SaveEaForm(getValues())
                                        .then(resp => {
                                          setValue('baseForm.formId', resp.formId)
                                          setValue('baseForm.formStatus', resp.formStatus)
                                          NavigateTo(navigate, '/engineer-audit/:id', { id: resp.formId })

                                          formId = resp.formId
                                          reload()

                                          enqueueSnackbar(t('Record Saved'), { variant: 'success' })
                                        })
                   }
                 })

  const handleOnDelete = async () =>
      setRequest({
                   callback: async () => {
                     await FormEaService.DeleteEaForm(getValues())
                                        .then(() => {
                                          enqueueSnackbar(t('Record Archived'), { variant: 'success' })
                                          NavigateTo(navigate, '/engineer-audit/all-record')
                                        })
                                        .catch(err => {
                                          enqueueSnackbar(err.response.data.message, { variant: 'error', autoHideDuration: null })
                                        })
                   }
                 })

  const onLoadNotifyList = async (isRejcet: boolean) =>
      await FormService.GetNotifyList('EA',
                                      getValues('baseForm.formId'),
                                      isRejcet,
                                      getValues('baseForm.districtId'),
                                      getValues('baseForm.teamId'),
                                      [
                                        {
                                          key  : 'vtci',
                                          value: getValues('viableToConductInspection') || '',
                                        }
                                      ])

  const onLoadHistoryList = async () =>
      await FormService.GetHistoryList(getValues('baseForm.formId'))

  const [FormSwitcher, handleOnComplete] = useFormSwitcher({
                                                             title                    : t('ENGINEER AUDIT'),
                                                             components               : [EAPartA(),
                                                                                         EAPartB(),
                                                                                         EAPartC(),
                                                                                         EAPartD(),
                                                                                         EAPartE(),
                                                                                         EAPartF()],
                                                             formOnLoadNotifyList     : onLoadNotifyList,
                                                             formOnLoadCommentList    : async () =>
                                                                 await FormService.GetCommentList(getValues('baseForm.formId')),
                                                             formOnSubmitComment      : async (comment) => {
                                                               await FormService.SubmitComment(getValues('baseForm.formId'), comment)
                                                             },
                                                             formOnLoadFormHistoryList: onLoadHistoryList,
                                                             formOnSubmit             : handleOnSubmit,
                                                             formOnDelete             : handleOnDelete,
                                                             formOnSave               : handleOnSave,
                                                             startStep                : step,
                                                             isLoading                : isLoading || isLoadingOption,
                                                             disableSave              : !formPermission?.canUpdate,
                                                             disableDelete            : !formPermission?.canDelete,
                                                             disableComment           : getValues('baseForm.formId') ? false : true,
                                                             approveText              : formStatus === FormStatusEnum.FORM_EA_AGREE_PRELIM_RESULT ? 'Agree' : undefined,
                                                             approveRequired          : formPermission?.workflowRequired,
                                                             endOfFlow                : formPermission?.endOfFlow,
                                                             isMounted                : isMounted.current
                                                           })

  return (
      <>
        <FormSwitcher />
        <ValidationToast control={ control } />
        <DevTool control={ control } />
      </>
  )
}