import { Control, useWatch }    from 'react-hook-form'
import { GlobalStateProps }     from '@providers/globalStore'
import { FormCRModel }          from '@services/model/form/form.CR.model'
import { FormStatusEnum }       from '@services/model/form/form.model'
import FormBinaryRadio          from '@components/form/binaryRadio'
import FormContainer            from '@components/form/container'
import FormController           from '@components/form/controller'
import FormDateTimePicker       from '@components/form/dateTimePicker'
import FormField                from '@components/form/field'
import FormFreeText             from '@components/form/freeText'
import FormImageUpload          from '@components/form/imageUpload'
import FormSelect               from '@components/form/select'
import FormTypography           from '@components/form/typography'
import { GetWorksOrderOptions } from '@utils/worksOrderOptions'
import moment                   from 'moment'
import FormMultipleSelect       from '@components/form/multipleSelect'
import { GetTeamOptions }       from '@utils/teamOptions'
import { GetDistrictOptions }   from '@utils/districtOptions'

export default ({
                    globalState,
                    control,
                    userInfo,
                    getValues,
                    formStatus
                }: {
    globalState: GlobalStateProps
    control: Control<FormCRModel>
    userInfo: any
    getValues: any
    formStatus: string
}) => {
    const uneditable = !(formStatus === FormStatusEnum.FORM_CR_DRAFT || formStatus === FormStatusEnum.FORM_CR_WORKS_INCOMPLETE)

    const [workOrderId] = getValues(['baseForm.workOrderId'])

    const contractNoList = globalState.contractList?.map(x => ({
        key  : x.id,
        value: x.contractNo
    })) ?? []

    const WorkOrderNo = () => {
        const [teamId, districtId] = useWatch({
                                                  control,
                                                  name: ['baseForm.teamId', 'baseForm.districtId'],
                                              })

        return (
            <FormController controllerProps={ {
                name : 'baseForm.workOrderId',
                control,
                rules: { required: true }
            } }>
                <FormSelect uneditable={ false }
                            fullWidth
                            options={ GetWorksOrderOptions(globalState?.worksOrderList
                                                                      ?.filter(x => !x.crLock || x.id == workOrderId) || [],
                                                           teamId,
                                                           districtId) } />
            </FormController>
        );
    }

  return (
    <FormContainer fkey="cr-a">
      <FormField fieldName="Contract No.">
        <FormController
          controllerProps={{
            name: 'baseForm.contractNoId',
            control,
            rules: { required: true },
          }}>
          <FormSelect options={contractNoList} uneditable={true} />
        </FormController>
      </FormField>

        <FormField fieldName='Team'>
            <FormController controllerProps={ { name: 'baseForm.teamId', control } }>
                <FormMultipleSelect
                    options={ GetTeamOptions(userInfo.teams) }
                    uneditable={ uneditable } />
            </FormController>
        </FormField>

        <FormField fieldName='District'>
            <FormController controllerProps={ { name: 'baseForm.districtId', control } }>
                <FormMultipleSelect
                    options={ GetDistrictOptions(userInfo.districts) }
                    uneditable={ uneditable } />
            </FormController>
        </FormField>

        <FormField fieldName='Works Order No.'>
            {
                uneditable
                ? <FormTypography value={ getValues('baseForm.workOrderNo') } />
                : <WorkOrderNo />
            }
        </FormField>

      <FormField fieldName="CR No.">
        <FormController
          controllerProps={{
            name: 'crNo',
            control,
          }}>
          <FormTypography />
        </FormController>
      </FormField>
      <FormField fieldName="Date of Completion of Works">
        <FormController
          controllerProps={{
            name: 'dateOfCompleteion',
            control,
            rules: { required: true },
          }}>
          <FormDateTimePicker 
            type="date" 
            uneditable={uneditable} 
            minDate={getValues('firstSubmittedCompletionDate') === undefined ? moment(new Date()).subtract(2, 'days').toDate() : moment(getValues('firstSubmittedCompletionDate')).toDate()}
            maxDate={moment(new Date()).toDate() }
            />
        </FormController>
      </FormField>
      <FormField fieldName="Apply SBV">
        <FormController
          controllerProps={{
            name: 'applySbv',
            control,
            rules: { required: true },
          }}>
          <FormBinaryRadio uneditable={uneditable} />
        </FormController>
      </FormField>
      <FormField fieldName="Image">
        <FormController
          controllerProps={{
            name: 'crImages',
            control,
          }}>
          <FormImageUpload uneditable={uneditable} multiple />
        </FormController>
      </FormField>
      <FormField fieldName="Contractor Remarks">
        <FormController
          controllerProps={{
            name: 'contractorRemarks',
            control,
          }}>
          <FormFreeText
            textfieldProps={{
              multiline: true,
              rows: 4,
            }}
            uneditable={uneditable}
          />
        </FormController>
      </FormField>
    </FormContainer>
  )
}
